const images = {
  example: require('./home-screen/example.png'),
  example4: require('./home-screen/example-4.png'),
  exampleBanner1: require('./home-screen/example-banner-1.png'),
  exampleBanner2: require('./home-screen/example-banner-2.png'),
  exampleBanner3: require('./home-screen/example-banner-3.png'),
  example2: require('./home-screen/example-2.png'),
  exampleImgPlan: require('./home-screen/example-img-plan.png'),
  logoExample: require('./home-screen/logos/logo-example.png'),
  logoExample2: require('./home-screen/logos/logo-example-2.png'),
  logoExample3: require('./home-screen/logos/logo-example-3.png'),

  bannerHome: require('./home-screen/banner-home.png'),
  bannerConsumer: require('./home-screen/banner-consumer.png'),
  bannerDefi: require('./home-screen/banner-defi.png'),
  bannerGaming: require('./home-screen/banner-gaming.png'),
  bannerCommunity: require('./home-screen/banner-community.png'),
  expandingVision: require('./home-screen/expanding-vision.png'),
  victonVideo: require('./home-screen/viction-video.jpg'),
  victionHorizon: require('./home-screen/viction-horizon.png'),
  saigonBuilder: require('./home-screen/saigon-builder.png'),
  tomoBuilder: require('./home-screen/tomo-builder.png'),
  slideBanner1: require('./home-screen/slide-banner-1.png'),
  slideBanner2: require('./home-screen/slide-banner-2.png'),
  slideBanner3: require('./home-screen/slide-banner-3.png'),
  slideBanner4: require('./home-screen/slide-banner-4.png'),
  slideBanner5: require('./home-screen/slide-banner-5.png'),
  bannerNetwork: require('./banner/banner-network.png'),
  bannerNetwork2: require('./banner/banner-network-2.png'),
  bannerNetwork3: require('./banner/banner-network-3.png'),
  bannerNetwork4: require('./banner/banner-network-4.png'),
  bannerNetwork5: require('./banner/banner-network-5.png'),
  bannerNetwork6: require('./banner/banner-network-6.png'),
  bannerSolution: require('./banner/banner-solution.png'),
  bannerConsumer1: require('./banner/banner-consumer.png'),
  bannerConsumer2: require('./banner/banner-consumer-2.png'),
  bannerGaming1: require('./banner/banner-gaming.png'),
  bannerGaming2: require('./banner/banner-gaming-2.png'),
  bannerGaming3: require('./banner/banner-gaming-3.png'),
  bannerDefi1: require('./banner/banner-defi.png'),

  binance: require('./home-screen/logo-ecosystem/binance.png'),
  btse: require('./home-screen/logo-ecosystem/btse.png'),
  bingX: require('./home-screen/logo-ecosystem/bingx-logo.png'),
  chainlinkLogo: require('./home-screen/logo-ecosystem/chain-link-logo.png'),
  bitget: require('./home-screen/logo-ecosystem/bidget-logo.png'),
  gateIo: require('./home-screen/logo-ecosystem/gate-logo.png'),
  mexc: require('./home-screen/logo-ecosystem/mexc-logo.png'),
  alchemy: require('./home-screen/logo-ecosystem/alchemy-pay-logo.png'),
  baryon: require('./home-screen/logo-ecosystem/baryon-logo.png'),
  biconomy: require('./home-screen/logo-ecosystem/biconomy-logo.png'),
  cobo: require('./home-screen/logo-ecosystem/cobo-logo.png'),
  dagora: require('./home-screen/logo-ecosystem/dagora-logo.png'),
  herond: require('./home-screen/logo-ecosystem/herond-logo.png'),
  impossible: require('./home-screen/logo-ecosystem/impossible-logo.png'),
  intOverse: require('./home-screen/logo-ecosystem/into-overse-logo.png'),
  layerZero: require('./home-screen/logo-ecosystem/layer-zero-logo.png'),
  metaOne: require('./home-screen/logo-ecosystem/meta-one-logo.png'),
  moonPay: require('./home-screen/logo-ecosystem/moon-pay-logo.png'),
  nowNodes: require('./home-screen/logo-ecosystem/now-nodes-logo.png'),
  poko: require('./home-screen/logo-ecosystem/poko-logo.png'),
  provalidator: require('./home-screen/logo-ecosystem/provalidator-logo.png'),
  pyth: require('./home-screen/logo-ecosystem/pyth-logo.png'),
  questN: require('./home-screen/logo-ecosystem/questN-logo.png'),
  ramper: require('./home-screen/logo-ecosystem/ramper-logo.png'),
  reneVerse: require('./home-screen/logo-ecosystem/rene-verse-logo.png'),
  rockX: require('./home-screen/logo-ecosystem/rockX-logo.png'),
  simplex: require('./home-screen/logo-ecosystem/simplex-logo.png'),
  stably: require('./home-screen/logo-ecosystem/stably-logo.png'),
  stealthEx: require('./home-screen/logo-ecosystem/stealth-ex-logo.png'),
  tezuka: require('./home-screen/logo-ecosystem/tezuka-logo.png'),
  tokenChart: require('./home-screen/logo-ecosystem/token-chart-logo.png'),
  blockPi: require('./home-screen/logo-ecosystem/blockPi-logo.png'),
  covalent: require('./home-screen/logo-ecosystem/covalent-logo.png'),
  particalNetwork: require('./home-screen/logo-ecosystem/partical-network-logo.png'),
  poolz: require('./home-screen/logo-ecosystem/poolz-logo.png'),
  space3: require('./home-screen/logo-ecosystem/space3-logo.png'),
  oneId: require('./home-screen/logo-ecosystem/oneID-logo.png'),
  forwards: require('./home-screen/logo-ecosystem/forwards-logo.png'),
  dojoSwap: require('./home-screen/logo-ecosystem/dojoSwap-logo.png'),
  aliniex: require('./home-screen/logo-ecosystem/aliniex-logo.png'),
  escrowDapp: require('./home-screen/logo-ecosystem/escrowdapp-logo.png'),
  desig: require('./home-screen/logo-ecosystem/desig-logo.png'),
  aitProtocolLogo: require('./home-screen/logo-ecosystem/ait-protocol-logo.png'),
  coin98SuperWalletLogo: require('./home-screen/logo-ecosystem/coin98-super-wallet-logo.png'),
  nftScanLogo: require('./home-screen/logo-ecosystem/nft-scan-logo.png'),
  nuvoLogo: require('./home-screen/logo-ecosystem/nuvo-logo.png'),
  soullyLogo: require('./home-screen/logo-ecosystem/soully-logo.png'),
  starshipLogo: require('./home-screen/logo-ecosystem/starship-logo.png'),
  veloLogo: require('./home-screen/logo-ecosystem/velo-logo.png'),
  victionLogo: require('./home-screen/logo-ecosystem/viction-logo.png'),
  wooXLogo: require('./home-screen/logo-ecosystem/woo-x-logo.png'),
  bitesLogo: require('./home-screen/logo-ecosystem/bites-logo.png'),
  bitMartLogo: require('./home-screen/logo-ecosystem/bit-mart-logo.png'),
  bitizenWalletLogo: require('./home-screen/logo-ecosystem/bitizen-wallet-logo.png'),
  bybitLogo: require('./home-screen/logo-ecosystem/bybit-logo.png'),
  chainsAtlasLogo: require('./home-screen/logo-ecosystem/chains-atlas-logo.png'),
  cocoCatLogo: require('./home-screen/logo-ecosystem/cocoCat-logo.png'),
  cusdLogo: require('./home-screen/logo-ecosystem/cusd-logo.png'),
  d3Logo: require('./home-screen/logo-ecosystem/d3-logo.png'),
  moriProtocolLogo: require('./home-screen/logo-ecosystem/mori-protocol-logo.png'),
  foxWalletLogo: require('./home-screen/logo-ecosystem/fox-wallet-logo.png'),
  giottusLogo: require('./home-screen/logo-ecosystem/giottus-logo.png'),
  htxLogo: require('./home-screen/logo-ecosystem/htx-logo.png'),
  humanodeLogo: require('./home-screen/logo-ecosystem/humanode-logo.png'),
  hyperlaneLogo: require('./home-screen/logo-ecosystem/hyperlane-logo.png'),
  magicSquareLogo: require('./home-screen/logo-ecosystem/magic-square-logo.png'),
  mudAILogo: require('./home-screen/logo-ecosystem/mudAI-logo.png'),
  taskOnLogo: require('./home-screen/logo-ecosystem/taskOn-logo.png'),
  arkenBridgeLogo: require('./home-screen/logo-ecosystem/arken-bridge-logo.png'),
  ceffuLogo: require('./home-screen/logo-ecosystem/ceffu-logo.png'),
  firstDigitalLabsLogo: require('./home-screen/logo-ecosystem/first-digital-labs-logo.png'),
  tmrGamesLogo: require('./home-screen/logo-ecosystem/tmrgames-logo.png'),
  usdvLogo: require('./home-screen/logo-ecosystem/usdv-logo.png'),
  tezukaLogo: require('./home-screen/logo-ecosystem/tezuka-logo.png'),
  authariumLogo: require('./home-screen/logo-ecosystem/autharium-logo.png'),
  chat3Logo: require('./home-screen/logo-ecosystem/chat-3-logo.png'),
  deFusionLogo: require('./home-screen/logo-ecosystem/deFusion-logo.png'),
  geckoTerminalLogo: require('./home-screen/logo-ecosystem/gecko-terminal-logo.png'),
  holdStationLogo: require('./home-screen/logo-ecosystem/hold-station-logo.png'),
  plenaLogo: require('./home-screen/logo-ecosystem/plena-logo.png'),
  micro3Logo: require('./home-screen/logo-ecosystem/project-82-logo.png'),
  simpleSwapLogo: require('./home-screen/logo-ecosystem/simple-swap-logo.png'),
  townStoryLogo: require('./home-screen/logo-ecosystem/town-story-logo.png'),
  cyborgLogo: require('./home-screen/logo-ecosystem/cyborg-logo.png'),
  hybrixLogo: require('./home-screen/logo-ecosystem/hybrix-logo.png'),
  krystalLogo: require('./home-screen/logo-ecosystem/krystal-logo.png'),
  mathWalletLogo: require('./home-screen/logo-ecosystem/math-wallet-logo.png'),
  midleLogo: require('./home-screen/logo-ecosystem/midle-logo.png'),
  sendTokensLogo: require('./home-screen/logo-ecosystem/sendTokens-logo.png'),
  solideLogo: require('./home-screen/logo-ecosystem/solide-logo.png'),
  subWalletLogo: require('./home-screen/logo-ecosystem/subWallet-logo.png'),
  wigwam: require('./home-screen/logo-ecosystem/wigwam.png'),
  revoke: require('./home-screen/logo-ecosystem/revoke.png'),
  omniHub: require('./home-screen/logo-ecosystem/omniHub.png'),
  woD: require('./home-screen/logo-ecosystem/woD.png'),
  lumoz: require('./home-screen/logo-ecosystem/lumoz.png'),
  polygon: require('./home-screen/logo-ecosystem/polygon.png'),
  nfts2me: require('./home-screen/logo-ecosystem/nfts2me.png'),
  ivy: require('./home-screen/logo-ecosystem/ivy.png'),
  contrarians: require('./home-screen/logo-ecosystem/contrarians.png'),
  chainlyze: require('./home-screen/logo-ecosystem/chainlyze.png'),
  zeroway: require('./home-screen/logo-ecosystem/zeroway.png'),
  stakeKit: require('./home-screen/logo-ecosystem/stakeKit.png'),
  blockWallet: require('./home-screen/logo-ecosystem/blockWallet.png'),
  blazpay: require('./home-screen/logo-ecosystem/blazpay.png'),
  cedeStore: require('./home-screen/logo-ecosystem/cedeStore.png'),
  omnisea: require('./home-screen/logo-ecosystem/omnisea.png'),
  l2Marathon: require('./home-screen/logo-ecosystem/l2Marathon.png'),
  meendart: require('./home-screen/logo-ecosystem/meendart.png'),
  quickIntel: require('./home-screen/logo-ecosystem/quickIntel.png'),
  eternals: require('./home-screen/logo-ecosystem/eternals.png'),
  domin: require('./home-screen/logo-ecosystem/domin.png'),
  domin: require('./home-screen/logo-ecosystem/domin.png'),
  galxe: require('./home-screen/logo-ecosystem/galxe.png'),
  glitchD: require('./home-screen/logo-ecosystem/glitchD.png'),
  interport: require('./home-screen/logo-ecosystem/interport.png'),

  // wallet-screen
  backgroundWallet: require('./wallet-screen/background-wallet.png'),
  backgroundWalletMobile: require('./wallet-screen/background-wallet-mobile.png'),
  backgroundWallet1: require('./wallet-screen/background-wallet-1.svg').default,
  backgroundWallet2: require('./wallet-screen/background-wallet-2.svg').default,
  backgroundWallet3: require('./wallet-screen/background-wallet-3.svg').default,
  backgroundWallet4: require('./wallet-screen/background-wallet-4.svg').default,
  screenSendWallet: require('./wallet-screen/screen-send-wallet.png'),
  screenConnect: require('./wallet-screen/screen-connect.png'),
  screenMainWallet: require('./wallet-screen/screen-main-wallet.png'),
  screenDappWallet: require('./wallet-screen/screen-dapp-wallet.png'),

  //wwc
  bgBanner: require('./world-wide-chain/bg-banner.png'),
  connectDots: require('./world-wide-chain/connect-dots.png'),
  wwc1: require('./world-wide-chain/wwc-1.png'),
  wwc2: require('./world-wide-chain/wwc-2.png'),
  wwc3: require('./world-wide-chain/wwc-3.png'),
  oneStop: require('./world-wide-chain/one-stop.png'),
  liberalImg: require('./world-wide-chain/liberal-img.png'),
  liberalImgMobile: require('./world-wide-chain/liberal-img-mobile.png'),
  gameImg: require('./world-wide-chain/game-img.png'),
  socialImg: require('./world-wide-chain/social-img.png'),
  nftImg: require('./world-wide-chain/nft-img.png'),
  gameImgMobile: require('./world-wide-chain/game-img-mobile.png'),
  socialImgMobile: require('./world-wide-chain/social-img-mobile.png'),
  nftImgMobile: require('./world-wide-chain/nft-img-mobile.png'),
  bgFooter: require('./world-wide-chain/bg-footer.png'),
  circleDot1: require('./world-wide-chain/circle-dot-1.png'),
  circleDot2: require('./world-wide-chain/circle-dot-2.png'),
  circleDot3: require('./world-wide-chain/circle-dot-3.png'),
  circleDot4: require('./world-wide-chain/circle-dot-4.png'),
  efficiency: require('./world-wide-chain/efficiency-1.png'),
  interoperability: require('./world-wide-chain/interoperability.png'),
  scalability: require('./world-wide-chain/scalability.png'),
  security: require('./world-wide-chain/security.png'),
  patternUp: require('./world-wide-chain/vic-pattern-up.png'),
  victionText: require('./world-wide-chain/viciton-text.svg').default,
  bgStarted: require('./world-wide-chain/bg-started.png'),
}

export default images
