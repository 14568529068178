import React, { useEffect, useMemo, useState } from 'react'
import TextTitle from 'components/TextTitle'
import ItemProject from 'components/ItemProject'
import { getLength } from 'commons/function'

import styles from './Ecosystem.module.scss'
import { arrProjectNew } from 'commons/constant'
import { Link } from 'react-router-dom'

function EcosystemScreen() {
  const [arrFilter, setArrFilter] = useState([
    {
      title: 'All',
      value: 'all',
    },
  ])
  const [arrData, setArrData] = useState([])
  const [filterChoose, setFilterChoose] = useState(arrFilter[0])
  const [isShowFilter, setIsShowFilter] = useState(false)

  const onFilter = useMemo(() => {
    if (filterChoose.value === 'all') {
      return arrProjectNew
    } else {
      return arrData.filter((item) => {
        return item.category.toLowerCase() === filterChoose.value
      })
    }
  }, [filterChoose])

  const handleFilter = (item) => () => {
    setFilterChoose(item)
  }

  const handleShowFilter = () => {
    setIsShowFilter(!isShowFilter)
  }

  useEffect(() => {
    let arrFilterTemp = arrProjectNew.map((item, i) => {
      return item.category
    })

    const uniqueSet = arrFilterTemp.reduce((unique, item) => {
      return unique.includes(item) ? unique : [...unique, item]
    }, [])

    const arrFilterConvert = uniqueSet.map((item) => {
      return {
        title: item,
        value: item.toLowerCase(),
      }
    })

    if (getLength(arrFilter) <= 1) {
      setArrFilter([...arrFilter, ...arrFilterConvert])
    }

    setArrData(arrProjectNew)
  }, [])

  return (
    <div className={styles.ecosystemWrapper}>
      <div className='ecosystem-container'>
        <div className='ecosystem-info'>
          <h1 className='ecosystem-info__title'>Viction Ecosystem</h1>

          <div className='ecosystem-info__description'>
            <div className='description-item description-item--text'>
              <TextTitle
                content='Discover a worldwide landscape of dapps and integrations within
                the Viction ecosystem. Join our mission to empower human
                potential, fostering a truly open and secure world.'
                isReserve
                type='small'
                classname='description-custom'
              />
            </div>

            <div className='description-item description-item--link'>
              <Link
                target='_blank'
                className='text-description text-base text-hover-underline'
                to='https://docs.google.com/forms/d/e/1FAIpQLSczKVJWYtigzCmaJeRIyoO-AqLFUmFxXa4ZuO2d9TPHW_zwbA/viewform?usp=sharing'
              >
                Submit Your Project
              </Link>
            </div>
          </div>
        </div>

        <div className='ecosystem-body-wrapper'>
          <div className='ecosystem-body'>
            <div className='ecosystem-filter-wrapper'>
              <div className='ecosystem-filter'>
                {arrFilter.map((item, i) => {
                  const itemActive = item.value === filterChoose.value

                  return (
                    <div
                      className={`item-filter text-base ${
                        itemActive ? 'text-hover-underline' : 'text-hover'
                      } ${itemActive && 'item-filter--active'}`}
                      onClick={handleFilter(item)}
                      key={i}
                    >
                      {item.title}
                    </div>
                  )
                })}
              </div>

              <div className='filter-dropdown-wrapper'>
                <div className='dropdown-container'>
                  display{' '}
                  <div className='dropdown-wrapper' onClick={handleShowFilter}>
                    <div className='text-dropdown'>{filterChoose.title}</div>
                    <span className='icon-web_arrow_down icon-arrow'></span>
                    <div
                      className={`filter-dropdown ${
                        isShowFilter && 'filter-dropdown--show'
                      }`}
                    >
                      <div className='filter-content'>
                        {arrFilter.map((item, i) => {
                          const itemActive =
                            item.value === filterChoose.value ? true : false
                          console.log({ itemActive })

                          return (
                            <div
                              className={`item-dropdown ${
                                itemActive && 'item-dropdown--active'
                              }`}
                              onClick={handleFilter(item)}
                              key={i}
                            >
                              {item.title}
                            </div>
                          )
                        })}

                        {/* <div className='item-dropdown'>abc</div>
                        <div className='item-dropdown'>abc</div> */}
                      </div>
                    </div>
                  </div>{' '}
                  projects
                </div>
              </div>
            </div>

            <div className='ecosystem-body__project'>
              {onFilter.map((item, i) => {
                return (
                  <ItemProject
                    item={item}
                    key={i}
                    type='small'
                    isBlank
                    className='item-custom'
                  />
                )
              })}
            </div>

            <div className='ecosystem-body__description text-sm'>
              This information, provided by third-party projects, is for general
              information only. <br /> Viction doesn't guarantee its accuracy,
              nor should it imply endorsement or affiliation.
            </div>
          </div>
        </div>

        <div className='footer-page'>
          <div className='footer-container'>
            <div className='text-content text-content--text-1'>Build</div>
            <div className='text-content text-content--text-2'>on</div>
            <div className='text-content text-content--text-3'>Viction</div>

            <div className='footer-menu'>
              <Link
                target='_blank'
                className='item-menu text-base text-hover-underline-white'
                to='https://docs.google.com/forms/d/e/1FAIpQLSczKVJWYtigzCmaJeRIyoO-AqLFUmFxXa4ZuO2d9TPHW_zwbA/viewform?usp=sharing'
              >
                Submit Your Project
              </Link>
              <div className='item-menu text-base text-hover-underline-white'>
                {/* Join DEV community */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EcosystemScreen
