import React from 'react'

import './textTitle.scss'

function TextTitle({
  subText,
  content,
  colorText,
  classname,
  title,
  isReserve = false,
  type,
}) {
  return (
    <div
      className={`text-head text-head${
        isReserve && '--reserve'
      } text-head--${type} ${classname}`}
    >
      {title && (
        <h2
          className='text-title'
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
      )}
      {subText && <div className='text-sub'>{subText}</div>}
      <div
        className='text-content'
        style={{ color: colorText }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  )
}

export default TextTitle
