import React from 'react'
import { AddToCalendarButton } from 'add-to-calendar-button-react'

import styles from './EventScreen.module.scss'
import images from 'assets/images'
import { convertDateMoment } from 'commons/function'
import { Link } from 'react-router-dom'
import ItemEvent from 'components/ItemEvent'
import useResizeWidth from 'controller/useResizeWidth'

const arrUpcoming = [
  {
    title: 'Viction Horizon',
    image: images.victionHorizon,
    description: `Viction Horizon is a startup hackathon designed for developers, creators, innovators, and visionaries around the globe. Our mission is to unlock human potential by transforming innovative ideas into successful startups within the Viction ecosystem`,
    time: 'Nov 2023',
    city: 'Online',
    country: '',
    startDate: '2023-11-02',
    link: 'https://horizon.viction.xyz/',
  },
]

const arrRecent = [
  {
    title: 'Saigon Builder Space',
    image: images.saigonBuilder,
    description: `Saigon Builder Space is your premier destination for a welcoming and dedicated working environment on the road to GM Vietnam. Whether you're a builder, creator, or developer from any background, our space is designed to bring people together to work, share ideas, and connect in real life.`,
    time: 'July 2nd 2023',
    city: 'HoChiMinh city',
    country: 'Viet Nam',
    link: 'https://twitter.com/BuilderSpace_',
  },
  {
    title: 'Tomo Builders Meetup',
    image: images.tomoBuilder,
    description: `Tomo Builders Meetup is a welcoming event exclusive for TomoChain Builders along side with Token 2049 in Singapore.`,
    time: 'Sep 12th 2023',
    city: 'Singapore',
    country: 'Viet Nam',
  },
]

function EventScreen() {
  const { widthResize } = useResizeWidth()

  return (
    <div className={styles.eventScreenWrapper}>
      <div className='upcoming-event'>
        <div className='upcoming-container'>
          <div className='upcoming-title'>Upcoming Events</div>

          <div className='upcoming-body'>
            {arrUpcoming.map((item, i) => {
              return <ItemEvent item={item} key={i} isUpcoming />
            })}
          </div>
        </div>
      </div>

      <div className='recent-event'>
        <div className='recent-event-container'>
          <div className='recent-title'>Recent Events</div>

          <div className='recent-body'>
            {arrRecent.map((item, i) => {
              return (
                // <div className='item-upcoming item-upcoming--recent' key={i}>
                //   <div className='item-upcoming__image'>
                //     <img src={item.image} alt='' className='banner-image' />
                //   </div>

                //   <div className='item-upcoming__info text-lg'>
                //     <div className='info-content'>
                //       <div className='info-title'>{item.title}</div>
                //       <div className='info-description'>{item.description}</div>
                //     </div>

                //     <div className='info-text'>
                //       <div className='info-text-time'>{item.time}</div>

                //       <div className='info-address'>{`${item.city}, ${item.country}`}</div>

                //       <div className='list-action'>
                //         <div className='link-action'>
                //           {item.link && (
                //             <Link
                //               to={item.link}
                //               target='_blank'
                //               className='text-link text-base text-hover-underline'
                //             >
                //               MORE DETAILS
                //             </Link>
                //           )}
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>
                <ItemEvent item={item} key={i} isRecent />
              )
            })}
          </div>
        </div>
      </div>

      <div className='footer-page'>
        <div className='footer-container'>
          <div className='text-content text-content--text-1'>Platform</div>
          <div className='text-content text-content--text-2'>for</div>
          <div className='text-content text-content--text-3'>
            Human {widthResize <= 768 && <br />} Potential
          </div>

          <div className='footer-menu'>
            <Link
              target='_blank'
              className='item-menu text-base text-hover-underline-white'
              to='https://docs.google.com/forms/d/e/1FAIpQLSczKVJWYtigzCmaJeRIyoO-AqLFUmFxXa4ZuO2d9TPHW_zwbA/viewform?usp=sharing'
            >
              Submit your Project
            </Link>
            <div className='item-menu text-hover-underline-white'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventScreen
