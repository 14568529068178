import moment from 'moment'

const isWindow = typeof window !== 'undefined'

const getLength = (value) => (value ? value.length : 0)

const upperCase = (value) => {
  return value ? value.toUpperCase() : value
}

const upperCaseFirstLetter = (lower) => {
  if (!lower) return lower
  const upper = lower.replace(/^\w/, (chr) => chr.toUpperCase())
  return upper
}

const upperCaseAfterSpace = (str) => {
  const splitStr = str.toLowerCase().split(' ')
  const upperStr = splitStr.map((item) => upperCaseFirstLetter(item))
  return upperStr.join(' ')
}

const convertDateMoment = (date, type) => {
  if (!date) return 'N/A'
  const dateFormat = new Date(date)
  const strTime = moment(dateFormat).format(type)
  return strTime
}

// const convertPasswordHMAC256Old = (password) => {
//   const hashPassword = crypto.HmacSHA256(password, 'coin98_token');
//   const hexhash = crypto.enc.Hex.stringify(hashPassword);
//   return hexhash;
// };

const getSubDomain = () => {
  if (!isWindow) return null

  const arrLocation = window.location.host.split('.')
  if (arrLocation?.length > 2 && !window.location.host.includes('192.168')) {
    return arrLocation[0]
  }
  return null
}

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getItemStorage = (key) => window.localStorage.getItem(key)

export {
  getLength,
  upperCase,
  upperCaseAfterSpace,
  upperCaseFirstLetter,
  convertDateMoment,
  // convertPasswordHMAC256Old,
  getSubDomain,
  sleep,
}
