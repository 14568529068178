import React from 'react'

import styles from './Network.module.scss'
import TextTitle from 'components/TextTitle'
import images from 'assets/images'
import BoxContent from 'components/BoxContent'
import { Link } from 'react-router-dom'
import ItemProject from 'components/ItemProject'

const arrMasterNode = [
  {
    title: 'Staking Rewards',
    content: `Validators and masternode operators unlock potential rewards
    in VIC tokens by staking their assets to fortify our
    network. Regular distributions keep the momentum going.`,
    image: images.bannerNetwork3,
  },
  {
    title: 'Transaction Fees',
    content: `As part of our inclusive ecosystem, validators also share in the transaction fees generated within the Viction network. This income source reflects the ebb and flow of network activity.`,
    image: images.bannerNetwork4,
  },
  {
    title: 'Masternode Rewards',
    content: `Our masternode operators are pivotal in producing blocks and reinforcing the network, and we recognize their contributions with additional rewards. For our operators, these rewards can be a significant boost.`,
    image: images.bannerNetwork5,
    isVertical: true,
    row: 2,
  },
  // {
  //   title: 'Masternode Rewards',
  //   content: `Our masternode operators are pivotal in producing blocks and reinforcing the network, and we recognize their contributions with additional rewards. For our operators, these rewards can be a significant boost.`,
  //   image: images.example2,
  //   isHideText: true,
  // },
]

const arrExchange = [
  {
    title: 'Binance',
    icon: images.binance,
    link: 'https://www.binance.com/en/trade/TOMO_USDT?type=spot',
  },
  {
    title: 'BTSE',
    icon: images.btse,
    link: 'https://www.btse.com/en/markets/TOMO-USD',
  },
  {
    title: 'BingX',
    icon: images.bingX,
    link: 'https://bingx.com/en-us/spot/TOMOUSDT/',
  },
  {
    title: 'Gate.io',
    icon: images.gateIo,
    link: 'https://www.gate.io/trade/TOMO_USDT',
  },
  {
    title: 'Bitget',
    icon: images.bitget,
    link: 'https://www.bitget.com/price/tomochain',
  },
  {
    title: 'MEXC',
    icon: images.mexc,
    link: 'https://www.mexc.com/exchange/TOMO_USDT',
  },
  {
    title: 'Biconomy',
    icon: images.biconomy,
    link: 'https://biconomy.com/',
  },
]

function NetworkScreen() {
  return (
    <div className={styles.networkWrapper}>
      <div className='header-height'>
        <div className='network-container'>
          <div className='network-left'>
            <div>
              <h1 className='text-title'>
                Empowering Decentralization, Earning Rewards
              </h1>

              <div className='text-description'>
                <TextTitle
                  isReserve
                  type='small'
                  classname='custom-title'
                  content={`Where decentralization matters, there's value. We're forging a path to a future where trust and control are in the hands of many, not few. Contribute your resources to secure our blockchain and earn $VIC in the process.`}
                />
              </div>
            </div>

            <div className='link-reward'>
              <Link
                to='https://docs.viction.xyz/'
                target='_blank'
                className='text-link text-base text-hover-underline'
              >
                RUN A MASTERNODE
              </Link>

              <Link
                to='https://master.tomochain.com/'
                target='_blank'
                className='text-link text-base text-hover-underline'
              >
                DELEGATE $VIC
              </Link>
            </div>
          </div>

          <div className='network-right'>
            <div className='img-box'>
              <img src={images.bannerNetwork} alt='' className='img-banner' />
            </div>

            <div className='box-value'>
              <div className='item-value'>
                <div className='item-value__title'>
                  CuRRENT NUMBER OF Masternode
                </div>
                <div className='item-value__value'>112</div>
              </div>

              <div className='item-value'>
                <div className='item-value__title'>CuRRENT Masternode APR</div>
                <div className='item-value__value'>28%</div>
              </div>
            </div>
          </div>
        </div>

        <div className='hearth-viction-wrapper'>
          <div className='hearth-text'>
            <TextTitle
              isReserve
              type='small'
              classname='hearth-text-custom'
              title={`Validators at the Heart of Viction'`}
              content='Validators are the backbone of our reliable ecosystem, diligently securing the network and ensuring transaction accuracy. They maintain consensus, demonstrating the power of collaboration in our robust blockchain platform.'
            />
          </div>

          <div className='hearth-banner'>
            <img src={images.bannerNetwork2} alt='' className='banner-image' />
          </div>
        </div>

        <BoxContent
          arrData={arrMasterNode}
          title='Masternode with MasterPerks'
        />

        <div className='delegator-wrapper'>
          <div className='delegator-container'>
            <h2 className='delegator-title'>
              Delegators Integral to Viction's Decentralization Journey
            </h2>

            <div className='delegator-body'>
              <div className='item-delegator'>
                <TextTitle
                  isReserve
                  type='small'
                  classname='item-delegator__text'
                  content={`Entrusting their tokens to validators, delegators strengthen Viction's ecosystem. Their active participation enhances network security, validates transactions, and maintains consensus. As you delegate $VIC, you're not just a participant, but a contributor to the robustness and integrity of our blockchain, fostering a decentralized and resilient platform for all.`}
                />
              </div>
              <div className='item-delegator'>
                <img
                  src={images.bannerNetwork6}
                  alt=''
                  className='delegator-img'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='exchange-wrapper'>
          <div className='exchange-container'>
            <h2 className='exchange-title'>Get $VIC on Exchanges</h2>

            <div className='exchange-body'>
              {arrExchange.map((item, i) => {
                return <ItemProject item={item} key={i} type='small' isBlank />
              })}
            </div>
          </div>
        </div>

        <div className='footer-page'>
          <div className='footer-container'>
            <div className='text-content text-content--text-1'>Embrace</div>
            <div className='text-content text-content--text-2'>Empower</div>
            <div className='text-content text-content--text-3'>
              Decentralized
            </div>

            <div className='footer-menu'>
              <Link
                to='https://docs.viction.xyz/'
                target='_blank'
                className='item-menu text-base text-hover-underline-white'
              >
                RUN A MASTERNODE
              </Link>
              <Link
                to='https://master.tomochain.com/'
                target='_blank'
                className='item-menu text-base text-hover-underline-white'
              >
                Explore masternode
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NetworkScreen
