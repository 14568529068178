import EcosystemScreen from 'pages/EcosystemScreen'
import EventScreen from 'pages/EventScreen'
import HubScreen from 'pages/HubScreen'
import NetworkScreen from 'pages/NetworkScreen'
import VictionWallet from 'pages/VicitonWallet'
import VictionDA from 'pages/VictionDA'
import ConsumerScreen from 'pages/VictionProject/ConsumerScreen'
import DefiScreen from 'pages/VictionProject/DefiScreen'
import GamingScreen from 'pages/VictionProject/GamingScreen'
import WorldWideChain from 'pages/WorldWideChain'

const { default: HomeScreen } = require('pages/HomeScreen')

const arrRouter = [
  {
    path: '/',
    element: <HomeScreen />,
  },
  {
    path: '/masternode',
    element: <NetworkScreen />,
  },
  {
    path: '/defi',
    element: <DefiScreen />,
  },
  {
    path: '/event',
    element: <EventScreen />,
  },
  {
    path: '/ecosystem',
    element: <EcosystemScreen />,
  },
  {
    path: '/community-hub',
    element: <HubScreen />,
  },
  {
    path: '/consumer-app',
    element: <ConsumerScreen />,
  },
  {
    path: '/gaming',
    element: <GamingScreen />,
  },
  {
    path: '/wallet',
    element: <VictionWallet />,
  },
  {
    path: '/world-wide-chain',
    element: <WorldWideChain />,
  },
  {
    path: '/viction-da',
    element: <VictionDA />,
  },
]

export { arrRouter }
