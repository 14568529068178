import React, { useContext, useEffect, useRef, useState } from 'react'

import './HeaderWallet.scss'
import { Link, useLocation } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'
import { ActionContext } from 'controller/context/ActionProvider'

function HeaderWallet() {
  const { positionY, widthResize } = useResizeWidth()
  const { pathname } = useLocation()

  const submenuRef = useRef(null)

  const { state } = useContext(ActionContext)

  const { isChangeBackgroundHeader } = state

  const [isShowMenu, setIsShowMenu] = useState(false)

  const isMobile = widthResize <= 600
  const isHome =
    pathname === '/' || pathname === '/event' || pathname === '/wallet'

  const isChangeColor = isHome && !isChangeBackgroundHeader ? true : false

  const handleClick = () => {
    setIsShowMenu(false)
  }

  const onClickOut = (e) => {
    if (submenuRef.current && !submenuRef.current.contains(e.target)) {
      setIsShowMenu(false)
    }
  }

  const onShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  const handleChooseMenu = () => {
    setIsShowMenu(false)
  }

  const handleCloseWeb = () => {
    // window.close()
    window.close()
  }

  useEffect(() => {
    window.addEventListener('mousedown', onClickOut)

    return () => window.removeEventListener('mousedown', onClickOut)
  }, [submenuRef])

  useEffect(() => {
    widthResize > 1280 && setIsShowMenu(false)
  }, [widthResize])

  useEffect(() => {
    if (isShowMenu) {
      document.querySelector('html').style.overflowY = 'hidden'
      document.querySelector('body').style.overflowY = 'hidden'
      document.querySelector('html').style.touchAction = 'none'
      document.querySelector('body').style.touchAction = 'none'
    } else {
      document.querySelector('html').style.overflowY = 'auto'
      document.querySelector('body').style.overflowY = 'auto'
      document.querySelector('html').style.touchAction = 'unset'
      document.querySelector('body').style.touchAction = 'unset'
    }
  }, [isShowMenu])

  return (
    <div
      className={`header-wallet-wrapper ${
        isShowMenu && 'header-wallet-wrapper--sub-open'
      }`}
    >
      <div className='header-container'>
        <div className='header-menu-wrapper'>
          <span
            className={`icon-web_logo_full icon-logo ${
              isShowMenu && 'icon-logo--menu-home'
            }`}
          ></span>

          <span
            className={`icon-${
              isShowMenu ? 'web_close' : 'web_menu'
            } icon-menu ${isShowMenu && 'icon-menu--menu-color'}`}
            onClick={onShowMenu}
          ></span>

          <div
            className={`header-link ${isShowMenu && 'header-link--show'} ${
              isShowMenu && 'header-link--menu-home'
            }`}
          >
            <Link
              to='https://docs.viction.xyz/general/how-to-connect-to-viction-network/viction-wallet'
              target='_blank'
              onClick={handleClick}
              className={`item-link text-hover-white`}
            >
              DOCS
            </Link>
            <Link
              to='https://blog.viction.xyz/'
              target='_blank'
              onClick={handleClick}
              className={`item-link text-hover-white`}
            >
              BLOG
            </Link>
            <Link
              // to='https://discord.com/invite/vCEJV44knr'
              to='mailto:support@viction.xyz'
              // to='https://mail.google.com/mail/u/0/#inbox?compose=VpCqJQvdQgVhrQFRVvkXMWqVKdNdbqXCdvkNNMFLVcLCSfknfQlLnMJvlcbShbRVvRtclgv'
              // target='_blank'
              onClick={handleClick}
              className={`item-link text-hover-white`}
              rel='noreferrer'
            >
              SUPPORT
            </Link>
            <a
              href='#download'
              onClick={handleClick}
              className={`item-link text-hover-white`}
            >
              DOWNLOAD
            </a>
            <div className='item-link item-link--line'>
              <div className='text-content'>|</div>
            </div>
            <Link
              to='/'
              className={`item-link item-link--back text-hover-white`}
            >
              <span className='icon-web_arrow_left'></span>
              BACK TO VICTION.XYZ
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderWallet
