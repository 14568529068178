import images from 'assets/images'
const isWindow = typeof window !== 'undefined'

const arrMenu = []

const arrFooter = [
  {
    title: 'Viction',
    menus: [
      {
        name: 'Viction Foundation',
        link: '',
        // isBlank: true,
        isHide: true,
      },
      {
        name: 'Blog',
        link: 'https://blog.viction.xyz/',
        isBlank: true,
      },
      {
        name: 'Careers',
        link: '/',
        isHide: true,
      },
      {
        name: 'Brand Assets',
        link: 'https://viction.link/brand-assets',
        isBlank: true,
      },
      {
        name: 'Privacy',
        link: '/',
        isHide: true,
      },
    ],
  },
  {
    title: 'Learn',
    isHide: true,
    menus: [
      {
        name: 'What is Viction',
        link: '/',
      },
      {
        name: 'Viction Wallet',
        link: '/',
      },
      {
        name: 'How to run a Node',
        link: '/',
      },
      {
        name: 'How to stake $VIC',
        link: '/',
      },
    ],
  },
  {
    title: 'Build',
    menus: [
      {
        name: 'Developer Guide',
        link: 'https://docs.viction.xyz/',
        // link: 'https://docs.tomochain.com/developer-guide',
        isBlank: true,
      },
      {
        name: 'Wallet Adaptor',
        link: '/',
        isHide: true,
      },
      {
        name: 'Hackathon',
        link: 'http://horizon.viction.xyz/',
        isBlank: true,
        // isComing: true,
      },
      {
        name: 'Network Status',
        link: 'https://stats.viction.xyz/',
        // link: 'https://www.vicscan.xyz/',
        isBlank: true,
      },
      {
        name: 'Masternodes',
        link: '/masternode',
      },
      {
        name: 'Block Explorer',
        link: 'https://www.vicscan.xyz/',
        isBlank: true,
      },
    ],
  },
  {
    title: 'Explore',
    menus: [
      {
        name: 'Viction Wallet',
        link: '/wallet',
        // isBlank: true,
      },
      {
        name: 'Ecosystem',
        link: '/ecosystem',
      },
      {
        name: 'Community Hub',
        link: '/community-hub',
      },
      {
        name: 'Events',
        link: '/event',
      },
      {
        name: 'Ambassador',
        link:
          'https://frontier-dao.notion.site/Tomo-Foundation-x-FrontierDAO-Tomodachi-Ambassador-Program-8ac59ffd5bf340b1a4f58c73b823e8cd',
        isBlank: true,
      },
    ],
  },
]

const arrFooterWwc = [
  {
    title: 'VICTION WWC',
    menus: [
      {
        name: 'Blog',
        link: 'https://blog.viction.xyz/',
        isBlank: true,
      },
    ],
  },
  {
    title: 'Learn',
    isHide: true,
    menus: [
      {
        name: 'What is Viction',
        link: '/',
      },
      {
        name: 'Viction Wallet',
        link: '/',
      },
      {
        name: 'How to run a Node',
        link: '/',
      },
      {
        name: 'How to stake $VIC',
        link: '/',
      },
    ],
  },
  {
    title: 'Build',
    menus: [
      {
        name: 'Developer Guide',
        link: 'https://docs.viction.xyz/',
        // link: 'https://docs.tomochain.com/developer-guide',
        isBlank: true,
      },
      {
        name: 'Products & Solutions',
        link: '/',
        isHide: true,
      },
    ],
  },
  {
    title: 'Explore',
    menus: [
      {
        name: 'Viction Wallet',
        link: '/wallet',
        // isBlank: true,
      },
      {
        name: 'Ecosystem',
        link: '/ecosystem',
      },
    ],
  },
  {
    title: 'Social',
    menus: [
      {
        name: 'Github',
        link: 'https://docs.viction.xyz',
        // isHide: true,
      },
      {
        name: 'Twitter',
        link: 'https://viction.link/twitter',
      },
      {
        name: 'Discord',
        link: 'https://viction.link/discord',
      },
      {
        name: 'Telegram',
        link: 'https://viction.link/telegram',
      },
    ],
  },
]

const arrSocial = [
  {
    title: 'Github',
    link: 'https://docs.viction.xyz',
    // isHide: true,
  },
  {
    title: 'Twitter',
    link: 'https://viction.link/twitter',
  },
  {
    title: 'Discord',
    link: 'https://viction.link/discord',
  },
  {
    title: 'Telegram',
    link: 'https://viction.link/telegram',
  },
]

const cateEco = {
  infrastructure: 'Infrastructure',
  consumerApp: 'Consumer App',
  deFi: 'DeFi',
  nft: "NFT",
  social: 'Social',
  nftCollectible: 'NFT Collectible',
  gaming: "Gaming"
}

const arrProjectNew = [
  {
    icon: images.aitProtocolLogo,
    title: 'AIT Protocol',
    link: 'https://ait.tech/',
    category: cateEco.infrastructure,
    // category: cateEco.social,
    isBlank: true,
  },
  {
    icon: images.alchemy,
    title: 'Alchemy Pay',
    link: 'https://alchemypay.org/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.aliniex,
    title: 'Aliniex',
    link: 'https://aliniex.com/mua-ban/tomo',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.arkenBridgeLogo,
    title: 'Arken Bridge',
    link: 'https://app.arken.finance/bridge',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.baryon,
    title: 'Baryon',
    link: 'https://www.baryon.network/',
    category: cateEco.deFi,
    // category: cateEco.deFi,
    isBlank: true,
  },
  {
    icon: images.binance,
    title: 'Binance',
    link: 'https://www.binance.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bingX,
    title: 'BingX',
    link: 'https://bingx.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.biconomy,
    title: 'Biconomy',
    link: 'https://biconomy.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bitesLogo,
    title: 'Bites',
    link: 'https://bites.xyz/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bitMartLogo,
    title: 'BitMart',
    link: 'https://www.bitmart.com/en-US',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bitget,
    title: 'Bitget',
    link: 'https://www.bitget.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bitizenWalletLogo,
    title: 'Bitizen Wallet',
    link: 'https://bitizen.org/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.blockPi,
    title: 'BlockPi',
    link: 'https://blockpi.io/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.btse,
    title: 'BTSE',
    link: 'https://www.btse.com/en/home',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bybitLogo,
    title: 'Bybit',
    link: 'https://www.bybit.com/en/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.chainsAtlasLogo,
    title: 'ChainsAtlas',
    link: 'https://www.chainsatlas.com/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.cobo,
    title: 'Cobo',
    link: 'https://www.cobo.com/',
    category: cateEco.infrastructure,
    // category: 'Tools',
    isBlank: true,
  },
  {
    icon: images.cocoCatLogo,
    title: 'CocoCat',
    link: 'https://www.cococat.io/#/',
    category: cateEco.social,
    // category: 'Tools',
    isBlank: true,
  },
  {
    icon: images.coin98SuperWalletLogo,
    title: 'Coin98 Super Wallet',
    link: 'https://coin98.com/',
    category: cateEco.consumerApp,
    // category: 'Tools',
    isBlank: true,
  },
  {
    icon: images.covalent,
    title: 'Covalent',
    link: 'https://www.covalenthq.com/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.cusdLogo,
    title: 'CUSD',
    link: 'https://www.cusd.xyz/',
    category: cateEco.deFi,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.d3Logo,
    title: 'D3',
    link: 'https://d3.app/vic',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.dagora,
    title: 'Dagora',
    link: 'https://dagora.xyz/',
    category: cateEco.nft,
    // category: 'NFTs',
    isBlank: true,
  },
  {
    icon: images.desig,
    title: 'Desig',
    link: 'https://desig.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.moriProtocolLogo,
    title: 'Mori Protocol',
    link: 'https://app.moriprotocol.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.escrowDapp,
    title: 'Escrow Dapp',
    link: 'https://escrowdapp.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.forwards,
    title: 'FORWARDS',
    link: 'https://forwardfactory.net/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.foxWalletLogo,
    title: 'FoxWallet',
    link: 'https://foxwallet.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.gateIo,
    title: 'Gate',
    link: 'https://www.gate.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.giottusLogo,
    title: 'Giottus',
    link: 'https://www.giottus.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.herond,
    title: 'Herond Browser',
    link: 'https://herond.org/',
    category: cateEco.consumerApp,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.htxLogo,
    title: 'HTX',
    link: 'https://www.htx.com/',
    category: cateEco.consumerApp,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.humanodeLogo,
    title: 'Humanode',
    link: 'https://t.co/sddGEMl680',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.hyperlaneLogo,
    title: 'Hyperlane',
    link: 'https://www.hyperlane.xyz/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.intOverse,
    title: 'INTOverse',
    link: 'https://intoverse.co/',
    category: cateEco.consumerApp,
    // category: cateEco.social,
    isBlank: true,
  },
  {
    icon: images.layerZero,
    title: 'LayerZero',
    link: 'https://layerzero.network/',
    category: cateEco.infrastructure,
    // category: 'Bridge',
    isBlank: true,
  },
  {
    icon: images.metaOne,
    title: 'MetaOne',
    link: 'http://metaone.gg',
    category: cateEco.gaming,
    // category: cateEco.gaming,
    isBlank: true,
  },
  {
    icon: images.mexc,
    title: 'MEXC',
    link: 'https://www.mexc.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.magicSquareLogo,
    title: 'Magic Square',
    link: 'https://magicsquare.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.moonPay,
    title: 'MoonPay',
    link: 'https://www.moonpay.com/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.mudAILogo,
    title: 'MudAI',
    link: 'https://www.mudai.city/',
    category: cateEco.infrastructure,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.nowNodes,
    title: 'NOWnodes',
    link: 'https://nownodes.io/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.nftScanLogo,
    title: 'NFTScan',
    link: 'https://www.nftscan.com/',
    category: cateEco.nft,
    isBlank: true,
  },
  {
    icon: images.nuvoLogo,
    title: 'Nuvo',
    link: 'https://www.nuvosphere.io/',
    category: cateEco.consumerApp,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.oneId,
    title: 'OneID',
    link: 'https://t.co/C6MtS27sZC',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.particalNetwork,
    title: 'Particle Network',
    link: 'https://particle.network/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.poko,
    title: 'Poko',
    link: 'https://www.pokoapp.xyz/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.poolz,
    title: 'Poolz',
    link: 'https://www.poolz.finance/',
    category: cateEco.deFi,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.provalidator,
    title: 'Provalidator (Nodereal)',
    link: 'https://provalidator.com/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.pyth,
    title: 'Pyth',
    link: 'https://pyth.network/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.questN,
    title: 'QuestN',
    link: 'https://questn.com/',
    category: cateEco.consumerApp,
    // category: 'Tools',
    isBlank: true,
  },
  {
    icon: images.ramper,
    title: 'Ramper',
    link: 'https://www.ramper.xyz/',
    category: cateEco.consumerApp,
    // category: 'Wallets',
    isBlank: true,
  },
  {
    icon: images.reneVerse,
    title: 'ReneVerse',
    link: 'https://reneverse.io/',
    category: cateEco.gaming,
    // category: cateEco.gaming,
    isBlank: true,
  },
  {
    icon: images.rockX,
    title: 'RockX',
    link: 'https://www.rockx.com/',
    category: cateEco.consumerApp,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.simplex,
    title: 'Simplex',
    link: 'https://www.simplex.com/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.soullyLogo,
    title: 'Soully',
    link: 'https://soully.io/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.space3,
    title: 'Space3',
    link: 'https://space3.gg/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.stably,
    title: 'Stably (CUSD)',
    link: 'https://stably.io/',
    category: cateEco.infrastructure,
    // category: 'Stablecoins',
    isBlank: true,
  },
  {
    icon: images.starshipLogo,
    title: 'Starship',
    link: 'https://starship.network/',
    category: cateEco.deFi,
    // category: 'Stablecoins',
    isBlank: true,
  },
  {
    icon: images.stealthEx,
    title: 'StealthEx',
    link: 'https://stealthex.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.taskOnLogo,
    title: 'TaskOn',
    link: 'https://taskon.xyz/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.tokenChart,
    title: 'TokenChart <br/> (WEconomy)',
    link: 'https://tokenchart.in/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.veloLogo,
    title: 'Velo',
    link: 'https://www.velo.org/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.victionLogo,
    title: 'Viction Wallet',
    link: 'https://viction.xyz/wallet',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.wooXLogo,
    title: 'WooX',
    link: 'https://woo.org/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.firstDigitalLabsLogo,
    title: 'First Digital Labs',
    link: 'https://firstdigitallabs.com/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.tmrGamesLogo,
    title: 'TMRGames',
    link: 'https://tmrgames.io/',
    category: cateEco.gaming,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.usdvLogo,
    title: 'USDV',
    link: 'https://usdv.money/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.ceffuLogo,
    title: 'Ceffu',
    link: 'https://www.ceffu.com/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.tezukaLogo,
    title: 'Tezuka',
    link: 'https://tezuka.io/',
    category: cateEco.nft,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.authariumLogo,
    title: 'Autharium',
    link: 'https://intro.autharium.jp/',
    category: cateEco.nft,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.geckoTerminalLogo,
    title: 'Gecko Terminal',
    link: 'https://www.geckoterminal.com/vi/tomochain/pools',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.micro3Logo,
    title: 'Micro3',
    link: 'https://micro3.io/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.chat3Logo,
    title: 'Chat3',
    link: 'https://chat3.one/',
    category: cateEco.social,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.deFusionLogo,
    title: 'deFusion',
    link: 'https://www.defusion.xyz/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.simpleSwapLogo,
    title: 'SimpleSwap',
    link: 'https://simpleswap.io/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.plenaLogo,
    title: 'Plena',
    link: 'https://www.plena.finance/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.townStoryLogo,
    title: 'TownStory',
    link: 'https://townstory.io/',
    category: cateEco.gaming,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.holdStationLogo,
    title: 'Holdstation',
    link: 'https://holdstation.com/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.krystalLogo,
    title: 'Krystal',
    link: 'https://defi.krystal.app/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.mathWalletLogo,
    title: 'Math Wallet',
    link: 'https://mathwallet.org/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.hybrixLogo,
    title: 'Hybrix',
    link: 'https://hybrix.io/en',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.sendTokensLogo,
    title: 'SendTokens',
    link: 'https://www.sendtokens.xyz/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.subWalletLogo,
    title: 'SubWallet',
    link: 'https://www.subwallet.app/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.cyborgLogo,
    title: 'Cyborg',
    link: 'https://www.cyborg.game/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.midleLogo,
    title: 'Midle',
    link: 'https://midle.io/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.solideLogo,
    title: 'Solide',
    link: 'https://dapp.solide0x.tech',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.wigwam,
    title: 'Wigwam',
    link: 'https://wigwam.app/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.revoke,
    title: 'Revoke.cash',
    link: 'https://revoke.cash/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.omniHub,
    title: 'OmniHub',
    link: 'https://omnihub.xyz/',
    category: cateEco.nft,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.woD,
    title: 'World of Dypians',
    link: 'https://www.worldofdypians.com/',
    category: cateEco.gaming,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.lumoz,
    title: 'Lumoz',
    link: 'http://lumoz.org/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.polygon,
    title: 'PolygonCDK',
    link: 'https://polygon.technology/polygon-cdk#',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.nfts2me,
    title: 'NFTs2ME',
    link: 'https://nfts2me.com/',
    category: cateEco.nft,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.ivy,
    title: 'Ivy',
    link: 'https://x.com/TheIvyNFT',
    category: cateEco.nftCollectible,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.contrarians,
    title: 'Contrarians',
    link: 'https://x.com/ContrariansNFT',
    category: cateEco.nftCollectible,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.eternals,
    title: 'Eternals',
    link: 'https://www.eternals.game/',
    category: cateEco.gaming,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.chainlyze,
    title: 'Chainlyze',
    link: 'https://app.chainlyze.ai/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.zeroway,
    title: 'Zeroway',
    link: 'https://zeroway.io/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.stakeKit,
    title: 'Stakekit',
    link: 'https://www.stakek.it/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.blockWallet,
    title: 'BlockWallet',
    link: 'https://blockwallet.io/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.blazpay,
    title: 'BlazPay',
    link: 'http://www.blazpay.com/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.cedeStore,
    title: 'Cede.store',
    link: 'https://cede.store/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.omnisea,
    title: 'Omnisea',
    link: 'https://omnisea.org/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.l2Marathon,
    title: 'L2 Marathon',
    link: 'https://l2marathon.com/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.meendart,
    title: 'MeendArt',
    link: 'https://www.meendart.com/en/artist',
    category: cateEco.nftCollectible,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.quickIntel,
    title: 'Quick Intel',
    link: 'https://quickintel.io/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.domin,
    title: 'Domin',
    link: 'https://www.domin.foundation/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.glitchD,
    title: 'GlitchD',
    link: 'https://glitchd.network/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.galxe,
    title: 'Galxe',
    link: 'https://galxe.com/',
    category: cateEco.consumerApp,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.interport,
    title: 'Interport',
    link: 'https://interport.fi/',
    category: cateEco.deFi,
    // category: 'Data',
    isBlank: true,
  },
]

const arrProject = [
  {
    icon: images.intOverse,
    title: 'INTOverse',
    link: 'https://intoverse.co/',
    category: cateEco.consumerApp,
    // category: cateEco.social,
    isBlank: true,
  },
  {
    icon: images.questN,
    title: 'QuestN',
    link: 'https://questn.com/',
    category: cateEco.infrastructure,
    // category: 'Tools',
    isBlank: true,
  },
  {
    icon: images.rockX,
    title: 'RockX',
    link: 'https://www.rockx.com/',
    category: cateEco.consumerApp,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.nowNodes,
    title: 'NOWnodes',
    link: 'https://nownodes.io/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.provalidator,
    title: 'Provalidator (Nodereal)',
    link: 'https://provalidator.com/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.blockPi,
    title: 'BlockPi',
    link: 'https://blockpi.io/',
    category: cateEco.infrastructure,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.covalent,
    title: 'Covalent',
    link: 'https://www.covalenthq.com/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.moonPay,
    title: 'MoonPay',
    link: 'https://www.moonpay.com/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.reneVerse,
    title: 'ReneVerse',
    link: 'https://reneverse.io/',
    category: cateEco.gaming,
    // category: cateEco.gaming,
    isBlank: true,
  },
  {
    icon: images.simplex,
    title: 'Simplex',
    link: 'https://www.simplex.com/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.alchemy,
    title: 'Alchemy Pay',
    link: 'https://alchemypay.org/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.poko,
    title: 'Poko',
    link: 'https://www.pokoapp.xyz/',
    category: cateEco.consumerApp,
    // category: 'Payments',
    isBlank: true,
  },
  {
    icon: images.metaOne,
    title: 'MetaOne',
    link: 'http://metaone.gg',
    category: cateEco.gaming,
    // category: cateEco.gaming,
    isBlank: true,
  },
  {
    icon: images.impossible,
    title: 'Impossible Finance',
    link: 'https://impossible.finance/',
    category: cateEco.deFi,
    // category: cateEco.deFi,
    isBlank: true,
  },
  {
    icon: images.cobo,
    title: 'Cobo',
    link: 'https://www.cobo.com/',
    category: cateEco.infrastructure,
    // category: 'Tools',
    isBlank: true,
  },
  {
    icon: images.tezuka,
    title: 'Tezuka',
    link: 'https://tezuka.io/',
    category: cateEco.deFi,
    // category: 'NFTs',
    isBlank: true,
  },
  {
    icon: images.herond,
    title: 'Herond Browser',
    link: 'https://herond.org/',
    category: cateEco.consumerApp,
    // category: cateEco.infrastructure,
    isBlank: true,
  },
  {
    icon: images.stably,
    title: 'Stably (CUSD)',
    link: 'https://stably.io/',
    category: cateEco.infrastructure,
    // category: 'Stablecoins',
    isBlank: true,
  },
  {
    icon: images.tokenChart,
    title: 'TokenChart (WEconomy)',
    link: 'https://tokenchart.in/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.binance,
    title: 'Binance',
    link: 'https://www.binance.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.btse,
    title: 'BTSE',
    link: 'https://www.btse.com/en/home',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.mexc,
    title: 'MEXC',
    link: 'https://www.mexc.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.biconomy,
    title: 'Biconomy',
    link: 'https://biconomy.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bingX,
    title: 'BingX',
    link: 'https://bingx.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.bitget,
    title: 'Bitget',
    link: 'https://www.bitget.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.gateIo,
    title: 'Gate',
    link: 'https://www.gate.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.pyth,
    title: 'Pyth',
    link: 'https://pyth.network/',
    category: cateEco.infrastructure,
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.baryon,
    title: 'Baryon',
    link: 'https://www.baryon.network/',
    category: cateEco.deFi,
    // category: cateEco.deFi,
    isBlank: true,
  },
  {
    icon: images.dagora,
    title: 'Dagora',
    link: 'https://dagora.xyz/',
    category: cateEco.nft,
    // category: 'NFTs',
    isBlank: true,
  },
  {
    icon: images.ramper,
    title: 'Ramper',
    link: 'https://www.ramper.xyz/',
    category: cateEco.consumerApp,
    // category: 'Wallets',
    isBlank: true,
  },
  {
    icon: images.layerZero,
    title: 'LayerZero',
    link: 'https://layerzero.network/',
    category: cateEco.infrastructure,
    // category: 'Bridge',
    isBlank: true,
  },
  // {
  //   icon: images.chainlinkLogo,
  //   title: 'Chainlink',
  //   link: 'https://chain.link/',
  //   category: cateEco.infrastructure,
  //   // category: 'Data',
  //   isBlank: true,
  // },
  {
    icon: images.stealthEx,
    title: 'StealthEx',
    link: 'https://stealthex.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.particalNetwork,
    title: 'Particle Network',
    link: 'https://particle.network/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.desig,
    title: 'Desig',
    link: 'https://desig.io/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.poolz,
    title: 'Poolz',
    link: 'https://www.poolz.finance/',
    category: cateEco.deFi,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.space3,
    title: 'Space3',
    link: 'https://space3.gg/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.oneId,
    title: 'OneID',
    link: 'https://t.co/C6MtS27sZC',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.forwards,
    title: 'FORWARDS',
    link: 'https://forwardfactory.net/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.dojoSwap,
    title: 'Dojo Swap',
    link: 'https://app.dojoswap.xyz/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.aliniex,
    title: 'Aliniex',
    link: 'https://aliniex.com/mua-ban/tomo',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.escrowDapp,
    title: 'Escrow Dapp',
    link: 'https://escrowdapp.com/',
    category: cateEco.consumerApp,
    // category: 'Exchanges',
    isBlank: true,
  },
]

const arrProjectSort = [
  // {
  //   icon: images.chainlinkLogo,
  //   title: 'Chainlink',
  //   link: 'https://chain.link/',
  //   category: '',
  //   // category: 'Data',
  //   isBlank: true,
  // },
  {
    icon: images.layerZero,
    title: 'LayerZero',
    link: 'https://layerzero.network/',
    category: '',
    // category: 'Bridge',
    isBlank: true,
  },
  // {
  //   icon: images.impossible,
  //   title: 'Impossible Finance',
  //   link: 'https://impossible.finance/',
  //   category: '',
  //   // category: cateEco.deFi,
  //   isBlank: true,
  // },
  {
    icon: images.pyth,
    title: 'Pyth',
    link: 'https://pyth.network/',
    category: '',
    // category: 'Data',
    isBlank: true,
  },
  {
    icon: images.covalent,
    title: 'Covalent',
    link: 'https://www.covalenthq.com/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  {
    icon: images.particalNetwork,
    title: 'Particle Network',
    link: 'https://particle.network/',
    category: cateEco.infrastructure,
    // category: 'Exchanges',
    isBlank: true,
  },
  // {
  //   icon: images.moonPay,
  //   title: 'MoonPay',
  //   link: 'https://www.moonpay.com/',
  //   category: '',
  //   // category: 'Payments',
  //   isBlank: true,
  // },
  // {
  //   icon: images.alchemy,
  //   title: 'Alchemy Pay',
  //   link: 'https://alchemypay.org/',
  //   category: '',
  //   // category: 'Payments',
  //   isBlank: true,
  // },
  // {
  //   icon: images.simplex,
  //   title: 'Simplex',
  //   link: 'https://www.simplex.com/',
  //   category: '',
  //   // category: 'Payments',
  //   isBlank: true,
  // },
  // {
  //   icon: images.questN,
  //   title: 'QuestN',
  //   link: 'https://questn.com/',
  //   category: '',
  //   // category: 'Tools',
  //   isBlank: true,
  // },
  // {
  //   icon: images.biconomy,
  //   title: 'Biconomy',
  //   link: 'https://biconomy.com/',
  //   category: '',
  //   // category: 'Exchanges',
  //   isBlank: true,
  // },
  // {
  //   icon: images.intOverse,
  //   title: 'INTOverse',
  //   link: 'https://intoverse.co/',
  //   category: '',
  //   // category: cateEco.social,
  //   isBlank: true,
  // },
  // {
  //   icon: images.metaOne,
  //   title: 'MetaOne',
  //   link: 'http://metaone.gg',
  //   category: '',
  //   // category: cateEco.gaming,
  //   isBlank: true,
  // },
]

export {
  isWindow,
  arrMenu,
  arrFooter,
  arrSocial,
  arrProject,
  arrProjectSort,
  arrProjectNew,
  arrFooterWwc
}
