import { isWindow } from 'commons/constant'
import React, { useEffect, useState } from 'react'

function useResizeWidth() {
  const [widthResize, setWidthResize] = useState()
  const [positionY, setPositionY] = useState(0)
  const isMobile = widthResize <= 600
  const [isScrollDown, setIsScrollDown] = useState(false)

  let lastScrollTop = isWindow && window.scrollY

  const onResizeScreen = () => {
    setWidthResize(window.innerWidth)
  }

  const onScrollPosition = () => {
    const position = isWindow && window.scrollY

    setPositionY(position)
  }

  const onScrollDirections = () => {
    setPositionY(window.scrollY)

    const scrollTopPosition =
      window.scrollY || document.documentElement.scrollTop

    if (scrollTopPosition > lastScrollTop) {
      setIsScrollDown(true)
    } else if (scrollTopPosition < lastScrollTop) {
      setIsScrollDown(false)
    }
    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition
  }

  useEffect(() => {
    if (isWindow) {
      setWidthResize(window.innerWidth)
      window.addEventListener('resize', onResizeScreen)
      window.addEventListener('scroll', onScrollPosition)
      window.addEventListener('scroll', onScrollDirections)

      return () => {
        window.removeEventListener('scroll', onScrollPosition)
        window.removeEventListener('resize', onResizeScreen)
        window.removeEventListener('scroll', onScrollDirections)
      }
    }
  }, [isWindow])

  return {
    widthResize,
    positionY,
    isMobile,
    isScrollDown,
  }
}

export default useResizeWidth
