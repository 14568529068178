import React, { useState } from 'react'

import './Footer.scss'
import { arrFooter, arrFooterWwc, arrSocial } from 'commons/constant'
import { Link, useLocation } from 'react-router-dom'
import images from 'assets/images'

function Footer() {
  const { pathname } = useLocation()
  const [txtSign, setTxtSign] = useState('')


  const isWwc = pathname === '/world-wide-chain' || pathname === '/viction-da'
  const arrFooterFinal = isWwc ? arrFooterWwc : arrFooter

  const onChangeText = (e) => {
    const { value } = e.target

    setTxtSign(value)
  }

  return (
    <div className={`footer-wrapper ${isWwc && 'footer-wrapper--wwc'}`}>
      {/* <div className='ft-mobile-wrapper'>
        <div className='head-login head-login--mobile'>
          <input
            type='text'
            value={txtSign}
            onChange={onChangeText}
            className='input-content'
            placeholder='Sign up for our newsletter to get the latest news'
          />
          <div className='button text-hover'>SIGN UP</div>
        </div>
      </div> */}

      <div className='footer-container'>
        <div className='footer-left'>
          {arrFooterFinal.map((item, i) => {
            return (
              <div className={`menus ${item.isHide && 'menus--hide'}`} key={i}>
                <div className='menu-title'>{item.title}</div>

                <div className='menu-list'>
                  {item.menus.map((itemChild, index) => {
                    return (
                      <div
                        className={`item-menu ${itemChild.isComing && 'item-menu--coming'
                          } ${itemChild.isHide && 'item-menu--hide'}`}
                        key={index}
                      >
                        <Link
                          to={itemChild.link}
                          className={`item-menu__text text-hover ${isWwc && 'text-hover-white'}`}
                          target={itemChild.isBlank && '_blank'}
                        >
                          {itemChild.name}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>

        {!isWwc &&
          <div className='footer-right'>
            <div className='head-social'>
              {arrSocial.map((item, i) => {
                return (
                  <div
                    className={`item-social ${item.isHide && 'item-social--hide'
                      }`}
                    key={i}
                  >
                    <Link
                      to={item.link}
                      target='_blank'
                      className='item-social-link text-hover-underline'
                    >
                      {item.title}
                    </Link>
                  </div>
                )
              })}
            </div>

            {/* <div className='head-login head-login--desktop'>
            <input
              type='text'
              value={txtSign}
              onChange={onChangeText}
              className='input-content'
              placeholder='Sign up for our newsletter to get the latest news'
            />
            <div className='button text-hover'>SIGN UP</div>
          </div> */}
          </div>
        }
      </div>

      {isWwc &&
        <div className='text-img'>
          <img src={images.victionText} alt="" className='viction-text' />
        </div>
      }
    </div>
  )
}

export default Footer
