import React from 'react'

import styles from './ItemProject.module.scss'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

function ItemProject({ item, type = 'default', isBlank = false, className }) {
  return (
    <div className={classNames(styles.itemProjectWrapper, className)}>
      <Link to={item.link} target={isBlank && '_blank'}>
        <div className={`item-project item-project--${type}`}>
          <img src={item.icon} alt='' className='icon-logo' />

          <div
            className='item-name'
            dangerouslySetInnerHTML={{ __html: item.title }}
          ></div>
        </div>
      </Link>
    </div>
  )
}

export default ItemProject
