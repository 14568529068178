import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import styles from './VictionWallet.module.scss'
import Footer from 'layouts/Footer'
import TextTitle from 'components/TextTitle'
import images from 'assets/images'
import useResizeWidth from 'controller/useResizeWidth'
import { ActionContext } from 'controller/context/ActionProvider'
import { Link } from 'react-router-dom'

const arrContent = [
  {
    title: 'Easily Send & Receive Crypto and NFT Assets',
    content:
      'Experience zero-gas fees and seamlessly transfer digital assets exclusively for Viction Wallet users.',
    image: images.screenSendWallet,
    background: '#ffffff',
    backgroundImg: images.backgroundWallet1,
  },
  {
    title: 'Instantly Connect to Decentralized Applications (dApps)',
    content:
      'Smoothly access Viction dApps across various sectors, including Decentralized Exchanges, Marketplaces, Staking, GameFi, Yield Farming, etc.',
    image: images.screenConnect,
    isReserve: true,
    background: '#988f86',
    backgroundImg: images.backgroundWallet2,
  },
  {
    title: 'Securely Store <br/> Your Assets',
    content:
      'Viction Wallet provides you with absolute control over your assets and information, ensuring that no third party can ever exert influence over your data.',
    image: images.screenMainWallet,
    background: '#F8F6D7',
    backgroundImg: images.backgroundWallet3,
  },
  {
    title: 'Seamlessly Navigate with an Intuitive Interface',
    content:
      'Seamlessly navigate the Viction ecosystem, facilitated by the intuitive simplicity and user-friendly interface of the Viction Wallet.',
    image: images.screenDappWallet,
    isReserve: true,
    background: '#434343',
    color: '#f8f6d7',
    backgroundImg: images.backgroundWallet4,
  },
]

const arrApp = [
  {
    name: 'Chrome',
    link:
      'https://chrome.google.com/webstore/detail/viction-wallet/nopnfnlbinpfoihclomelncopjiioain?hl=vi',
  },
  {
    name: 'iOS',
    link: 'https://apps.apple.com/vn/app/viction-wallet-by-coin98/id1436476145',
  },
  {
    name: 'Android',
    link:
      'https://play.google.com/store/apps/details?id=viction.coin98.crypto.wallet&hl=vi',
  },
]

function VictionWallet() {
  const walletRef = useRef(null)
  const installRef = useRef(null)
  const { positionY, widthResize } = useResizeWidth()
  const { setState } = useContext(ActionContext)

  const { setIsChangeBackgroundHeader } = setState

  const isInViewport = useIsInViewport(installRef)
  const isMobile = widthResize <= 768

  const [heightElm, setHeightElm] = useState()

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false)

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    )

    useEffect(() => {
      observer.observe(ref.current)

      return () => {
        observer.disconnect()
      }
    }, [ref, observer])

    return isIntersecting
  }

  useEffect(() => {
    walletRef.current && setHeightElm(walletRef.current.clientHeight)
  }, [walletRef])

  useEffect(() => {
    setIsChangeBackgroundHeader(isInViewport)
  }, [isInViewport])

  return (
    <div
      className={styles.victionWallet}
      style={{ '--height-elm': `${heightElm}px` }}
    >
      <div className='box-background'>
        <img
          src={images[isMobile ? 'backgroundWalletMobile' : 'backgroundWallet']}
          alt=''
          className='background'
        />
      </div>

      <div className='viction-wallet-wrapper' ref={walletRef}>
        <div className='wallet-banner'>
          <div className='banner-text'>
            <div className='banner-text--title'>
              Your Friendly <br /> Viction Wallet
            </div>
            <a
              href='#download'
              className='banner-text--link text-hover-underline-white-2'
            >
              INSTALL VICTION WALLET
            </a>
          </div>

          <div className='banner-description'>
            <TextTitle
              classname='description-custom'
              content='An <span>efficient and straightforward</span> solution to manage digital assets and access the Web3 world on Viction.'
            />

            {/* <TextTitle
              classname='description-custom'
              content='Connect to an <span>open and secure</span> world on Viction, where humanity flourishes.'
            /> */}
          </div>
        </div>

        <div className='section-container'>
          <div className='section-wrapper'>
            {arrContent.map((item, i) => {
              return (
                <div
                  className='wallet-section wallet-section--section-1'
                  style={{ backgroundColor: item.background }}
                  key={i}
                >
                  <div className='wallet-section-wrapper'>
                    <div
                      className={`wallet-section-container ${
                        item.isReserve && 'wallet-section-container--reserve'
                      }`}
                    >
                      <div
                        className={`section-content ${
                          item.isReserve && 'section-content--reserve'
                        }`}
                        style={{ color: item.color }}
                      >
                        <h2
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></h2>

                        <div className='section-content--description'>
                          <TextTitle
                            isReserve
                            classname={`section-description-custom ${
                              item.color &&
                              'section-description-custom--custom-color'
                            }`}
                            colorText={item.color}
                            content={item.content}
                          />
                        </div>
                      </div>

                      <div
                        className={`section-img ${
                          item.isReserve && 'section-img--reserve'
                        }`}
                      >
                        <img
                          src={item.backgroundImg}
                          className='img-background'
                          alt=''
                        />
                        <img src={item.image} alt='' className='img-screen' />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='box-install' id='download' ref={installRef}>
          <div className='box-install-container'>
            <h1 className='install-title'>
              Get Started with <br /> Viction Wallet
            </h1>

            <div className='install-app'>
              {arrApp.map((item, i) => {
                return (
                  <Link
                    className='item-app-link'
                    to={item.link}
                    target='_blank'
                    key={i}
                  >
                    <div className='item-app'>
                      <h3>{item.name}</h3>
                      <span className='icon-web_arrow_up_right icon-arrow'></span>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default VictionWallet
