import React from 'react'

import styles from '../VictionProject.module.scss'
import images from 'assets/images'
import BoxContent from 'components/BoxContent'
import ItemProject from 'components/ItemProject'
import { Link } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const arrInfo = [
  {
    title: 'Security',
    content: `We utilize the security of blockchain network, offering robust features such as consensus mechanisms, encryption, and smart contract auditing. This instills confidence in developers and users alike.`,
    image: images.bannerSolution,
  },
  {
    title: 'Performance',
    content: `
    Our transaction speeds and scalability make it an ideal choice for consumer apps, ensuring that users experience minimal delays and congestion, even during peak usage.
    `,
    image: images.bannerConsumer1,
  },
  {
    title: 'Economical',
    content: `
    With low transaction fees and efficient resource management, our cost-effective infrastructure allows developers to create consumer apps that are economically viable and sustainable, ultimately benefiting both creators and users.
    `,
    image: images.bannerNetwork5,
    isVertical: true,
  },
  {
    title: 'Friendliness',
    content: `
    Viction's user-friendly development environment and tools simplify the app creation process, reducing barriers for developers and enhancing the overall user experience, which is essential for consumer apps.
    `,
    image: images.bannerConsumer2,
    isVertical: true,
  },
]

const arrProject = [
  {
    title: 'Tezuka',
    icon: images.logoExample,
  },
  {
    title: 'BTSE',
    icon: images.logoExample2,
  },
  {
    title: 'TomoStats',
    icon: images.logoExample3,
  },
  {
    title: 'ThreeFold',
    icon: images.logoExample,
  },
  {
    title: 'API3',
    icon: images.logoExample2,
  },
  {
    title: 'TomoStats',
    icon: images.logoExample3,
  },
  {
    title: 'ThreeFold',
    icon: images.logoExample,
  },
]

function ConsumerScreen() {
  const { widthResize } = useResizeWidth()

  return (
    <div className={styles.victionProjectWrapper}>
      <div className='defi-banner-wrapper'>
        <div className='defi-banner-head'>
          <h1 className='head-title'>Build Apps That Foster Human Potential</h1>

          <div className='head-link'>
            <div className='link-wrapper'>
              <Link
                target='_blank'
                to='https://docs.viction.xyz/'
                className='item-link text-base text-hover-underline'
              >
                Get Started
              </Link>
              <Link
                to='/community-hub'
                className='item-link text-base text-hover-underline'
              >
                Join Dev Community
              </Link>
            </div>
          </div>
        </div>

        <div className='defi-banner-img w-36'>
          <img src={images.bannerConsumer} alt='' className='banner-img' />

          <div className='text-description text-lg'>
            Shaping human growth, not things, Viction enables Web3 projects with
            optimal infrastructure to redefine user experience.
          </div>
        </div>
      </div>

      {/* <div className='project-list'>
        <h2 className='project-title'>Projects on Viction</h2>

        <div className='project-body w-18'>
          {arrProject.map((item, i) => {
            return <ItemProject item={item} type='small' key={i} />
          })}
        </div>

        <div className='project-description w-36'>
          <div className='description-text text-sm'>
            This information, provided by third-party projects, is for general
            information only. <br /> Viction doesn't guarantee its accuracy, nor
            should it imply endorsement or affiliation.
          </div>
        </div>
      </div> */}

      <BoxContent
        title={
          widthResize >= 1280
            ? `Why Choose Viction <br/> for Your Consumer App?`
            : `Why Choose Viction for Your Consumer App?`
        }
        arrData={arrInfo}
      />

      <div className='footer-page footer-page--consumer'>
        <div className='footer-container'>
          <div className='text-content text-content--text-1'>Built</div>
          <div className='text-content text-content--text-2'>on</div>
          <div className='text-content text-content--text-3'>Viction</div>

          <div className='footer-menu'>
            <Link
              target='_blank'
              to='https://docs.viction.xyz/'
              className='item-menu text-base text-hover-underline-white'
            >
              Get Started
            </Link>
            <Link
              to='/community-hub'
              className='item-menu text-base text-hover-underline-white'
            >
              Join Dev Community
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConsumerScreen
