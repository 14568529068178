import React from 'react'

import styles from '../VictionProject.module.scss'
import images from 'assets/images'
import BoxContent from 'components/BoxContent'
import ItemProject from 'components/ItemProject'
import { Link } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const arrInfo = [
  {
    title: 'Security',
    content: `Viction prioritizes security through consensus mechanism and advanced encryption techniques. Our robust security measures protect DeFi assets and data, reducing the risk of hacks and vulnerabilities.
    `,
    image: images.bannerSolution,
  },
  {
    title: 'Transparency',
    content: `Viction offers a transparent and immutable ledger, ensuring that all transactions and smart contracts are openly verifiable on the blockchain. This transparency builds trust among users and participants in the DeFi ecosystem.`,
    image: images.bannerNetwork3,
  },
  {
    title: 'Scalability',
    content: `Viction's high throughput and scalability make it well-suited for DeFi applications with growing user bases. Its ability to handle a large number of transactions per second ensures that DeFi platforms built on Viction can accommodate increasing demand without sacrificing performance.`,
    image: images.bannerGaming2,
    isVertical: true,
  },
  {
    title: 'Efficiency',
    content: `Viction's efficient infrastructure minimizes transaction costs and processing times, enabling DeFi users to conduct transactions, trade, and interact with smart contracts swiftly and cost-effectively. This efficiency enhances the overall user experience in the DeFi space.`,
    image: images.bannerDefi1,
    isVertical: true,
  },
]

const arrProject = [
  {
    title: 'Tezuka',
    icon: images.logoExample,
  },
  {
    title: 'BTSE',
    icon: images.logoExample2,
  },
  {
    title: 'TomoStats',
    icon: images.logoExample3,
  },
  {
    title: 'ThreeFold',
    icon: images.logoExample,
  },
  {
    title: 'API3',
    icon: images.logoExample2,
  },
  {
    title: 'TomoStats',
    icon: images.logoExample3,
  },
  {
    title: 'ThreeFold',
    icon: images.logoExample,
  },
]

function DefiScreen() {
  const { widthResize } = useResizeWidth()

  return (
    <div className={styles.victionProjectWrapper}>
      <div className='defi-banner-wrapper'>
        <div className='defi-banner-head'>
          <h1 className='head-title'>
            Design DeFi Solutions That Empower Financial Freedom
          </h1>

          <div className='head-link'>
            <div className='link-wrapper'>
              <Link
                target='_blank'
                to='https://docs.viction.xyz/'
                className='item-link text-base text-hover-underline'
              >
                Get Started
              </Link>
              <Link
                to='/community-hub'
                className='item-link text-base text-hover-underline'
              >
                Join Dev Community
              </Link>
            </div>
          </div>
        </div>

        <div className='defi-banner-img'>
          <img src={images.bannerDefi} alt='' className='banner-img' />

          <div className='text-description text-lg'>
            Fostering Decentralization through Collaboration, Viction provides
            Toolkits for faster, securer and more convenient Defi Product
            Developmemt.
          </div>
        </div>
      </div>

      {/* <div className='project-list'>
        <h2 className='project-title'>Projects on Viction</h2>

        <div className='project-body w-18'>
          {arrProject.map((item, i) => {
            return <ItemProject item={item} type='small' key={i} />
          })}
        </div>

        <div className='project-description w-36'>
          <div className='description-text text-sm'>
            This information, provided by third-party projects, is for general
            information only. <br /> Viction doesn't guarantee its accuracy, nor
            should it imply endorsement or affiliation.
          </div>
        </div>
      </div> */}

      <BoxContent
        title={
          widthResize >= 1280
            ? `Why Choose Viction <br/> for Your DeFi Solutions?`
            : `Why Choose Viction for Your DeFi Solutions?`
        }
        arrData={arrInfo}
      />

      <div className='footer-page footer-page--defi'>
        <div className='footer-container'>
          <div className='text-content text-content--text-1'>Built</div>
          <div className='text-content text-content--text-2'>on</div>
          <div className='text-content text-content--text-3'>Viction</div>

          <div className='footer-menu'>
            <Link
              target='_blank'
              to='https://docs.viction.xyz/'
              className='item-menu text-base text-hover-underline-white'
            >
              Get Started
            </Link>
            <Link
              to='/community-hub'
              className='item-menu text-base text-hover-underline-white'
            >
              Join Dev Community
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefiScreen
