import { AddToCalendarButton } from 'add-to-calendar-button-react'
import React from 'react'

import './ItemEvent.scss'
import { Link } from 'react-router-dom'

function ItemEvent({ item, className, isRecent = false, isUpcoming = false }) {
  return (
    <div
      className={`item-event ${isRecent && 'item-event--recent'} ${className}`}
    >
      <div className='item-upcoming__image'>
        <img src={item.image} alt='' className='banner-image' />
      </div>

      <div className='item-upcoming__info text-lg'>
        <div className='info-content'>
          <div className='info-title'>{item.title}</div>
          <div className='info-description'>{item.description}</div>
        </div>

        <div className='info-text'>
          <div className='info-text-time'>
            {/* {convertDateMoment(item.time, 'LL')} */}
            {item.time}
          </div>

          <div className='info-address'>{`${item.city}${item.country && ','} ${
            item.country
          }`}</div>

          <div className='list-action'>
            {isUpcoming && (
              <div className='link-action'>
                <AddToCalendarButton
                  name={item.title}
                  startDate={item.startDate}
                  // endDate='2024-01-09'
                  options={['Apple', 'Google']}
                  hideCheckmark={true}
                  trigger='click'
                ></AddToCalendarButton>
              </div>
            )}
            <div className='link-action'>
              {item.link && (
                <Link
                  to={item.link}
                  target='_blank'
                  className={`text-link text-base ${
                    isRecent
                      ? 'text-hover-underline'
                      : 'text-hover-underline-white'
                  }`}
                >
                  MORE DETAILS
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemEvent
