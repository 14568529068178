import React, { useEffect, useRef, useState } from 'react'

import styles from './HomeScreen.module.scss'
import TextTitle from 'components/TextTitle'
import images from 'assets/images'
import Slider from 'react-slick'
import ItemProject from 'components/ItemProject'
import useResizeWidth from 'controller/useResizeWidth'
import { Link } from 'react-router-dom'
import { arrProjectSort } from 'commons/constant'

const arrSlide = [
  {
    title: 'Gas Fee',
    value: '$0.0001',
    index: 0,
    image: images.slideBanner1,
  },
  {
    title: 'Block Time',
    value: '2s',
    index: 1,
    image: images.slideBanner2,
  },
  {
    title: 'Transaction Per Second',
    value: '2,000',
    index: 2,
    image: images.slideBanner3,
  },
  {
    title: 'Mainnet Transactions',
    value: '880M+',
    index: 3,
    image: images.slideBanner4,
  },
  {
    title: 'Masternode',
    value: '150',
    index: 4,
    image: images.slideBanner5,
  },
]

function HomeScreen() {
  const textSlideRef = useRef()
  const containerSlideRef = useRef()
  const slideRef = useRef()
  const projectRef = useRef(null)

  const { widthResize } = useResizeWidth()

  const [widthSlide, setWidthSlide] = useState(0)
  const [slideActive, setSlideActive] = useState(arrSlide[0].index)
  const [isDisableBtn, setIsDisableBtn] = useState({
    left: true,
    right: false,
  })
  const [scrollPositionX, setScrollPositionX] = useState(0)

  const imageSlideStatus = arrSlide.map((item, i) => {
    return {
      image: item.image,
    }
  })

  const onSlideChoose = (item) => () => {
    setSlideActive(item.index)

    slideRef.current.slickGoTo(item.index)
  }

  const onChangeSlide = (current, next) => {
    setSlideActive(next)
  }

  const handleDirection = (status, number) => () => {
    projectRef.current.scrollLeft += number
    const positionX = scrollPositionX + number
    const checkPosition = positionX >= 500
    // const checkPosition =
    // widthResize <= 1024 ? positionX >= 4500 : positionX >= 4000

    if (status === 'right') {
      if (!checkPosition) {
        setScrollPositionX(scrollPositionX + number)
        setIsDisableBtn({
          left: false,
          right: false,
        })
      } else {
        setIsDisableBtn({
          left: false,
          right: true,
        })
        return null
      }
    } else {
      if (!(positionX < 0)) {
        setScrollPositionX(scrollPositionX + number)
        setIsDisableBtn({
          left: false,
          right: false,
        })
      } else {
        setIsDisableBtn({
          left: true,
          right: false,
        })
        return null
      }
    }
  }

  useEffect(() => {
    if (textSlideRef.current && containerSlideRef.current) {
      setWidthSlide(
        containerSlideRef.current.offsetWidth -
          textSlideRef.current.offsetWidth -
          24
      )
    }
  }, [textSlideRef, containerSlideRef])

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    beforeChange: onChangeSlide,
    className: 'custom-slide',
  }

  const settingBanners = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 6000,
    className: 'custom-slide-banner',
  }

  return (
    <div className={styles.homeScreenWrapper}>
      <div className='home-banner'>
        <div className='banner-container'>
          <div className='banner-text'>
            <div className='banner-text-container'>
              <TextTitle
                subText='VISION'
                content='Building an open and secure world where humanity flourishes'
              />
              <TextTitle
                subText='WE ARE VICTION'
                classname='title-custom'
                content='The Layer-1 Blockchain that has the drive and ability to impact,
                enable, and empower people, through a commitment to
                accessibility, education, and community'
              />

              <div className='box-link w-44'>
                <Link
                  to='https://docs.viction.xyz/'
                  target='_blank'
                  className='item-link text-hover-underline-white'
                >
                  Build on Viction
                </Link>

                <Link
                  to='/community-hub'
                  className='item-link text-hover-underline-white'
                >
                  Join the community
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='slide-banner'>
          <img src={images.bannerHome} alt='' className='img-slide' />
          {/* <Slider {...settingBanners}>
            <img src={images.exampleBanner1} alt='' className='img-slide' />
            <img src={images.exampleBanner2} alt='' className='img-slide' />
            <img src={images.exampleBanner3} alt='' className='img-slide' />
          </Slider> */}
        </div>
      </div>

      <div className='home-content-wrapper'>
        <div className='home-content-title'>
          <TextTitle
            isReserve
            type='small'
            title='Expanding Vision'
            content='Fueling innovation and fostering a future where every vision has the potential to become a victory. With Viction, we journey together towards a brighter, more inclusive future.'
          />
        </div>

        <div className='img-box'>
          <img src={images.expandingVision} alt='' className='img-content' />
        </div>

        <div className='home-content-title home-content-title--section-slide'>
          <TextTitle
            isReserve
            type='small'
            title='Enabling Achievement'
            content='Leverage zero gas fee, speedy, secure, and reliable transactions that foster growth and success.'
          />
        </div>

        <div
          className='body-content-slide'
          ref={containerSlideRef}
          style={{ '--width-slide': `${widthSlide}px` }}
        >
          <div className='text-slide' ref={textSlideRef}>
            {arrSlide.map((item, i) => {
              const itemActive = slideActive === item.index

              return (
                <div
                  className={`item-slide-wrapper ${
                    itemActive && 'item-slide-wrapper--active'
                  }`}
                  onClick={onSlideChoose(item)}
                  key={i}
                >
                  <div
                    className={`item-slide ${
                      itemActive && 'item-slide--active'
                    }`}
                  >
                    <div className='item-slide__title'>{item.title}</div>
                    <div className='item-slide__value'>{item.value}</div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className='slide-body'>
            <Slider {...settings} ref={slideRef}>
              {imageSlideStatus?.map((item, i) => {
                return (
                  <img src={item.image} alt='' className='img-slide' key={i} />
                )
              })}
            </Slider>
          </div>

          {arrSlide.map((item, i) => {
            return (
              <div className='item-content-status' key={i}>
                <img src={item.image} alt='' className='image-status' />

                <div className='text-status'>
                  <div className='text-status__title'>{item.title}</div>
                  <div className='text-status__value'>{item.value}</div>
                </div>
              </div>
            )
          })}
        </div>

        <div className='box-video'>
          {/* <video
            className='box-video__content'
            id='video-banner'
            autoPlay
            playsInline
            loop
            muted
            data-keepPlaying
            // ref={videoRef}
          >
            <source src='videos/video-example.mp4' type='video/mp4' />
            Your browser is not supported!
          </video> */}
          <img src={images.victonVideo} alt='' className='box-video__content' />

          <div className='box-video__text-wrapper'>
            <div className='text-item'>
              <TextTitle
                isReserve
                classname='text-video-custom'
                title='Platform for Potential'
                content='Viction is the platform for potential. We’re making blockchain easy and useful for everyone, every day.'
              />
            </div>

            <div className='text-item text-item--menu-list text-base'>
              <div className='menu-item'>
                <Link
                  to='/gaming'
                  className='menu-item__text text-hover-underline-white-black'
                >
                  GAMING
                </Link>
              </div>
              <div className='menu-item'>
                <Link
                  to='/consumer-app'
                  className='menu-item__text text-hover-underline-white-black'
                >
                  CONSUMER APP
                </Link>
              </div>
              <div className='menu-item'>
                <Link
                  to='/defi'
                  className='menu-item__text text-hover-underline-white-black'
                >
                  DEFI
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='viction-project'>
          <div className='viction-project__text'>
            <TextTitle
              isReserve
              classname='text-custom'
              type='small'
              title='Encouraging Mass Adoption'
              content={`Viction, an efficient, rapidly growing blockchain ecosystem, is poised to expand vision and encourage mass adoption. Explore the expansive ecosystem we're building.`}
            />
          </div>

          <div className='viction-project__link'>
            <Link
              to='/ecosystem'
              className='text-link text-base text-hover-underline'
            >
              ALl Projects on Viction
            </Link>
          </div>
        </div>
      </div>

      <div className='viction-project-list' ref={projectRef}>
        {arrProjectSort.map((item, i) => {
          return (
            <div className='item-wrapper'>
              <ItemProject
                item={item}
                key={i}
                // type='small'
                isBlank={item.isBlank}
              />
            </div>
          )
        })}
      </div>

      <div className='box-direction box-direction--no-mobile'>
        <span
          className={`icon-web_arrow_left icon-arrow ${
            isDisableBtn.left && 'icon-arrow--left-disable'
          }`}
          id='arrow-left'
          onClick={handleDirection('left', -500)}
        ></span>
        <span
          className={`icon-web_arrow_right icon-arrow ${
            isDisableBtn.right && 'icon-arrow--right-disable'
          }`}
          id='arrow-right'
          onClick={handleDirection('right', 500)}
        ></span>
      </div>

      <div className='victon-footer-page'>
        <div className='footer-container'>
          <div className='text-content text-content--text-1'>Empower</div>
          <div className='text-content text-content--text-2'>Human</div>
          <div className='text-content text-content--text-3'>Potential</div>

          <div className='footer-menu'>
            <Link
              to='https://docs.viction.xyz/'
              target='_blank'
              className='item-menu text-base text-hover-underline-white'
            >
              Build on Viction
            </Link>
            <Link
              to='/hub'
              className='item-menu text-base text-hover-underline-white'
            >
              Join the community
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
