const { createContext, useState } = require('react')

export const ActionContext = createContext()

export function ActionProvider({ children }) {
  const [isChangeBackgroundHeader, setIsChangeBackgroundHeader] = useState(
    false
  )
  const [isHideFt, setIsHideFt] = useState(false)

  const state = {
    isChangeBackgroundHeader,
    isHideFt,
  }

  const setState = {
    setIsChangeBackgroundHeader,
    setIsHideFt,
  }

  const values = {
    state,
    setState,
  }

  return (
    <ActionContext.Provider value={values}>{children}</ActionContext.Provider>
  )
}
