import React from 'react'

import './BoxContent.scss'

function BoxContent({ arrData, className, title }) {
  return (
    <div className={`masternode-wrapper ${className}`}>
      <div className='masternode-container'>
        <h2
          className='masternode-title'
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>

        <div className='masternode-body'>
          {arrData.map((item, i) => {
            return (
              <div
                className={`item-node ${
                  item.isVertical && 'item-node--vertical'
                }`}
                key={i}
                style={{ '--row': item.row }}
              >
                {(item.title || item.content) && (
                  <div className='item-node__head'>
                    <div className='head-title'>{item.title}</div>

                    <div className='head-description text-lg'>
                      {item.content}
                    </div>
                  </div>
                )}

                {item.image && (
                  <div className='item-node__img'>
                    <img src={item.image} alt='' className='img-node' />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BoxContent
