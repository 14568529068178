import React, { useRef } from 'react'

import styles from './HubScreen.module.scss'
import TextTitle from 'components/TextTitle'
import images from 'assets/images'
import ItemEvent from 'components/ItemEvent'
import { Link } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const arrUpcoming = [
  {
    title: 'Viction Horizon',
    image: images.victionHorizon,
    description: `Viction Horizon is a startup hackathon designed for developers, creators, innovators, and visionaries around the globe. Our mission is to unlock human potential by transforming innovative ideas into successful startups within the Viction ecosystem`,
    time: 'Nov 2023',
    city: 'Online',
    country: '',
    startDate: '2023-11-02',
    link: 'https://horizon.viction.xyz/',
  },
]

function HubScreen() {
  const programEventRef = useRef(null)
  const { widthResize } = useResizeWidth()
  console.log(programEventRef)

  const handleScroll = () => {
    window.scrollTo({
      top: programEventRef?.current?.offsetTop, // offset for header height
    })
  }

  return (
    <div className={styles.hubWrapper}>
      <div className='hub-container'>
        <div className='hub-banner-wrapper'>
          <div className='hub-banner'>
            <h1 className='hub-banner__title'>
              Welcome to the Viction Community
            </h1>

            <div className='hub-banner__description'>
              <div className='description-item'>
                <TextTitle
                  content='Here at the intersection of technology and humanity, we are all
                connected in shaping a brighter future, one where innovation knows
                no bounds, where inclusivity reigns supreme, and where together,
                we amplify the essence of humanity. Explore, collaborate, and
                become a driving force in the Viction Community.'
                  isReserve
                  type='small'
                  classname='description-text-custom'
                />
              </div>

              <div className='description-item description-item--link'>
                <div
                  // target='_blank'
                  className='description-text text-base text-hover-underline'
                  onClick={handleScroll}
                  // to='https://docs.google.com/forms/d/e/1FAIpQLSczKVJWYtigzCmaJeRIyoO-AqLFUmFxXa4ZuO2d9TPHW_zwbA/viewform?usp=sharing'
                >
                  {/* Submit Your Project */}
                  Explore our Programs & Events
                </div>
              </div>
            </div>

            <div className='hub-banner__img'>
              <img src={images.bannerCommunity} alt='' className='banner-img' />

              <h2 className='text-img'>
                When individual members flourish,{' '}
                {widthResize >= 1280 && <br />} the community prospers.
              </h2>
            </div>
          </div>
        </div>

        <div className='hub-program-event-wrapper' ref={programEventRef}>
          <div className='hub-program-event'>
            <div className='program-event program-event--no-margin'>
              <div className='program-event__title'>
                Explore Our Programs & Events
              </div>

              <div className='program-event__description text-lg'>
                Learn. Build. Drive.
              </div>

              <div className='program-event__body'>
                {arrUpcoming.map((item, i) => {
                  return <ItemEvent item={item} key={i} isUpcoming />
                })}
              </div>
            </div>
          </div>
        </div>

        <div className='footer-page'>
          <div className='footer-container'>
            <div className='text-content text-content--text-1'>Platform</div>
            <div className='text-content text-content--text-2'>for</div>
            <div className='text-content text-content--text-3'>
              Human {widthResize <= 768 && <br />} Potential
            </div>

            {/* <div className='footer-menu'>
              <div className='item-menu text-base text-hover-underline-white'>
              Submit your Project
              </div>
              <div className='item-menu text-base text-hover-underline-white'>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HubScreen
