import React, { useContext, useEffect, useState } from 'react'

import styles from './NotFound.module.scss'
import { ActionContext } from 'controller/context/ActionProvider'

function NotFound() {
  const { setState } = useContext(ActionContext)
  const { setIsHideFt } = setState

  const [heightFt, setHeightFt] = useState(0)

  useEffect(() => {
    const elmFt = document.querySelector('.footer-wrapper')

    setHeightFt(elmFt.clientHeight)
    setIsHideFt(true)
  }, [])

  return (
    <div
      className={styles.notFoundWrapper}
      style={{ '--height-ft': `${heightFt}px` }}
    >
      <h3>404 | Page Not Found</h3>
    </div>
  )
}

export default NotFound
