'use client'

import React, { useEffect, useRef, useState } from 'react'

import styles from './WorldWideChain.module.scss'
import images from 'assets/images'
import { Link } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const arrOneStop = [
  {
    icon: images.wwc1,
    content: 'Foundational infrastructure built to meet security requirements for high sensivity applications'
  },
  {
    icon: images.wwc2,
    content: 'Ultra-high throughput'
  },
  {
    icon: images.wwc3,
    content: 'Zero-knowledge powered with smart contracts'
  },
]

function WorldWideChain() {
  const { isMobile } = useResizeWidth()

  const imgFtRef = useRef(null)
  const [heightFt, setHeightFt] = useState(0)

  useEffect(() => {
    setHeightFt(imgFtRef.current?.clientHeight)
  }, [imgFtRef])

  useEffect(() => {
    document.title = 'Viction World Wide Chain. Scale Beyond Limits'
    document.getElementsByTagName("META")[3].content = 'Viction World Wide Chain offers a novel solution with a network of app chains operating concurrently, all anchored by a common settlement on Viction.'
    document.getElementsByTagName("META")[13].content = 'Viction World Wide Chain offers a novel solution with a network of app chains operating concurrently, all anchored by a common settlement on Viction.'
  }, [])

  return (
    <div className={styles.wwcWrapper}>
      <div className="wwc-container">
        <div className="wwc-banner">
          <img src={images.bgBanner} className='img-banner' />

          <div className="wwc-banner__text">
            <h1 className="text-title">
              World Wide Chain
            </h1>

            <div className="text-description">
              Building the internet of appchains. Establishing
              a foundation for extensive scalability.
            </div>

            <div className="button-actions">
              <Link to='https://blog.viction.xyz/viction-world-wide-chain/' target='_blank' className='btn btn--btn-1'>
                Getting started <span className="icon-web_arrow_up_right icon-arrow"></span>
              </Link>

              <Link to='https://docs.google.com/document/d/1ZaPVZwogiS0B1DLPqPoJ1TQyUz8i9zUZSZjLoADhaXg/edit?usp=sharing' target='_blank' className='btn btn--btn-2'>
                Connect with an expert <span className="icon-web_arrow_up_right icon-arrow"></span>
              </Link>
            </div>
          </div>
        </div>

        <div className="wwc-content-wrapper">
          <div className="wwc-connect-dot">
            <img src={images.patternUp} alt="" className='pattern-up' />

            <div className="connect-dot-container">
              <div className="dot-text">
                <h1 className="dot-text__title">
                  Connect the dots
                </h1>
                <div className="dot-text__sub-title">
                  The Internet of appchains unifed on one Protocol
                </div>
              </div>
            </div>

            <div className="img-connect">
              <img src={images.connectDots} className='connect-bg' />
            </div>

            <div className="text-dot-description">
              Be a part of a hyper-interconnected appchains. Not just co-exists, but also collaborate, {isMobile && <br />} enabling a fluid exchange of assets and data across diverse networks. Facilitating {isMobile && <br />} seamless communication and interoperability among the different zones.
            </div>

            <div className="icon-box">
              <span className="icon-web_block icon-block"></span>
            </div>

            <div className="description-text">
              <div className="description-title">
                Together, {isMobile && <br />} we connect to create
              </div>
              <Link to='https://blog.viction.xyz/viction-world-wide-chain/' target='_blank' className="description-sub text-hover-underline">
                Learn more {!isMobile && 'about Viction World Wide Chain Protocol'}
              </Link>
            </div>
          </div>

          <div className="one-stop">
            <div className="one-stop-container">
              <h1 className="title-one">
                One<span>-</span>stop {isMobile && <br />} Solution
              </h1>
              <div className="sub-title-one">
                Everything you need to scale up beyond your existing application.
              </div>

              <div className="one-stop-content">
                <div className="content-text">
                  {
                    arrOneStop.map((item, i) => {
                      return (
                        <div className="content-item" key={i}>
                          <div className="icon-content">
                            <img src={item.icon} className='icon-wwc' />
                          </div>

                          <div className="text-content">
                            {item.content}
                          </div>
                        </div>
                      )
                    })
                  }

                  <div className="line"></div>

                  <div className="text-description">
                    To grow with precision,
                    from mystery to mastery.</div>
                </div>

                <div className="content-img">
                  <img src={images.oneStop} className='img-one-stop' />
                </div>
              </div>
            </div>
          </div>

          <div className="liberal">
            <div className="liberal-container">
              <div className="liberal-text-container">
                <h1 className="liberal-title">
                  Liberal {isMobile && <br />} Building
                </h1>
                <div className="liberal-title-sub">
                  Your token, your chain
                </div>
              </div>

              <img src={images[isMobile ? 'liberalImgMobile' : 'liberalImg']} className='bg-img' />

              <div className="liberal-description">
                Build agile and innovate faster.  Customisable stacks based on your business's needs
              </div>
            </div>
          </div>

          <div className="scale-beyond">
            <div className="scale-beyond-container">
              <div className="beyond-text">
                <h1 className='beyond-text--title'>Scale {isMobile && <br />} Beyond Limits</h1>
                <div className='beyond-text--sub-title'>
                  Still you but greater. Be a part of an inclusive community
                  and together create the Internet of Appchains.
                </div>
              </div>

              <div className="beyond-img">
                <div className="item-beyond">
                  <img src={images[isMobile ? 'gameImgMobile' : 'gameImg']} className='img-beyond' />
                </div>
                <div className="item-beyond">
                  <img src={images[isMobile ? 'socialImgMobile' : 'socialImg']} className='img-beyond img-beyond--img-2' />
                  <img src={images[isMobile ? 'nftImgMobile' : 'nftImg']} className='img-beyond' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="connect-create" style={{ '--height-ft': heightFt }}>
          <div className="connect-create-container">
            <h1 className="connect-title">
              Together, <br />
              We Connect to Create
            </h1>
            <div className='head-link'>
              <div className='link-wrapper'>
                <Link
                  target='_blank'
                  to='https://docs.viction.xyz/'
                  className='item-link text-base text-hover-underline'
                >
                  Resource  & Knowledge Center
                </Link>
                <Link
                  to='/community-hub'
                  className='item-link text-base text-hover-underline'
                >
                  Join our community
                </Link>
              </div>
            </div>
          </div>

          <img src={images.bgFooter} alt="" className='bg-footer' ref={imgFtRef} />
        </div>
      </div>
    </div>
  )
}

export default WorldWideChain