import React, { useEffect } from 'react'

import styles from './VictionDA.module.scss'
import images from 'assets/images'
import { Link } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const arrFuture = [
  {
    title: 'Advanced Cryptographic Methods',
    content: 'Incorporate cutting-edge cryptographic research (SNARKs or STARKs) to improve the scalability and efficiency of the data availability proofs.'
  },
  {
    title: 'Quantum-Resistant Cryptography',
    content: 'Utilizing quantum-resistant algorithms to mitigate future threats'
  },
  {
    title: 'Multi-Namespace Support',
    content: 'Parallel Namespace Processing: Reduce overhead for multi-dataset apps.'
  },
  {
    title: 'Improved Data Encoding Schemes',
    content: 'Advanced data encoding schemes for enhanced data availability verification efficiency.'
  },
  {
    title: 'Intelligent Data Storage and Retrieval',
    content: 'Smart caching with AI: Get data quicker, keep networks lighter.'
  },
  {
    title: 'Customizable Data Availability Solutions',
    content: 'Offer tailored data availability solutions for specific industry needs, providing more flexibility than a one-size-fits-all approach.'
  },
]

function VictionDA() {
  const { isMobile } = useResizeWidth()

  useEffect(() => {
    document.querySelector('html').style.overflowY = 'unset'
    document.querySelector('body').style.overflowY = 'unset'
    document.title = 'Viction Data Availability'
    document.getElementsByTagName("META")[3].content = 'VictionDA revolutionizes blockchain with high data availability and cost-effective data posting, empowering applications to leverage cutting-edge technology efficiently.'
    document.getElementsByTagName("META")[13].content = 'VictionDA revolutionizes blockchain with high data availability and cost-effective data posting, empowering applications to leverage cutting-edge technology efficiently.'
  }, [])

  return (
    <div className={styles.victionDAWrapper}>
      <div className="viction-da-container">
        <div className="da-banner">
          <div className="da-banner-container">
            <h1 className='banner-title'>Better together,
              to the next layer </h1>

            <div className="banner-description">
              Viction DA, the interconnecting data layer for Web3, {isMobile && <br />} breaking down communication barriers between {isMobile && <br />} blockchain platforms.
            </div>

            <div className="button-actions">
              <Link to='https://docs.viction.xyz/viction-data-availability' target='_blank' className='btn btn--btn-1'>
                Start Building <span className="icon-web_arrow_up_right icon-arrow"></span>
              </Link>

              <Link to='https://docs.google.com/forms/d/e/1FAIpQLSeL-BS43rMP_AQSTxwjLFC-lBduE01rmA9Ipny5xeHyrcrKLw/viewform' target='_blank' className='btn btn--btn-2'>
                Get in Touch <span className="icon-web_arrow_up_right icon-arrow"></span>
              </Link>
            </div>
          </div>

          <div className="video-box">
            <video
              className='video'
              id='video-banner'
              autoPlay
              playsInline
              loop
              muted
              data-keepPlaying
            >
              <source
                src='videos/viction-da.mp4'
                type='video/mp4'
              />
              <source src='videos/circle-loop.webm' type='video/webm' />
              Your browser is not supported!
            </video>
          </div>

          <img src={images.patternUp} alt="" className='pattern-up' />
        </div>

        <div className="your-rollup">
          <div className="your-rollup-container">
            <div className="your-rollup-text">
              <h1 className='rollup-title'>
                Supercharge Your Rollups
              </h1>
              <div className="rollup-description">
                Viction DA a cutting-edge module crafted to ensure that data is securely stored. Remains retrievable and verifiably available across decentralized platforms.
              </div>
            </div>

            <div className="your-rollup-content">
              <div className="item-rollup">
                <div className="item-rollup__title">
                  Scalability
                </div>

                <div className="item-img">
                  <img src={images.scalability} alt="" className='img-item' />
                </div>

                <div className="item-content">
                  Victon DA lets light nodes verify data with less download using DAS & NMTs to reduce network congestion
                </div>
              </div>
              <div className="item-rollup">
                <div className="item-rollup__title">
                  Efficiency
                </div>

                <div className="item-img">
                  <img src={images.efficiency} alt="" className='img-item' />
                </div>

                <div className="item-content">
                  Namespaced Merkle Trees (NMTs) allow applications to download only relevant data, optimizing bandwidth usage
                </div>
              </div>
              <div className="item-rollup item-rollup--circle-img">
                <img src={images.circleDot1} alt="" className='img-circle' />
              </div>

              <div className="item-rollup item-rollup--circle-img">
                <img src={images.circleDot2} alt="" className='img-circle' />
              </div>
              <div className="item-rollup">
                <div className="item-rollup__title">
                  Security
                </div>

                <div className="item-img">
                  <img src={images.security} alt="" className='img-item' />
                </div>

                <div className="item-content">
                  Victon DA combines strong 2D Reed-Solomon encoding, and fraud proofs for securing data
                </div>
              </div>
              <div className="item-rollup">
                <div className="item-rollup__title">
                  Interoperability
                </div>

                <div className="item-img">
                  <img src={images.interoperability} alt="" className='img-item' />
                </div>

                <div className="item-content">
                  Viction DA integrates seamlessly with existing rollup stacks, providing a comprehensive ecosystem for builders
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="the-block">
          <div className="the-block-container">
            <div className="block-left">
              <h1 className="block-left__title">
                Blockchains Without the Block
              </h1>
              <div className="block-left__content">
                Victon DA seamlessly integrates with existing rollup solutions like zkEVMs (Polygon & GlitchD) and OP stacks, fostering a comprehensive ecosystem for builders and enterprises seeking reliable data availability and integrity within blockchain technology.
              </div>
            </div>

            <div className="block-right">
              <img src={images.circleDot3} alt="" className='block-right__img' />
            </div>
          </div>
        </div>

        <div className="the-future">
          <div className="the-future-container">
            <div className="future-text">
              <h1 className="future-text__title">
                The Future is Here: <br /> Built in Solutions that Rock
              </h1>
              <div className="future-text__description">
                We're gearing up for innovation, constantly exploring features that empower developers. Viction DA equips you to win in the ever-evolving blockchain industry
              </div>
            </div>

            <div className="future-content">
              {
                arrFuture.map((item, i) => {
                  return (
                    <div className="item-future" key={i}>
                      <div className="item-future__title">
                        {item.title}
                      </div>
                      <div className="item-future__content">
                        {item.content}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="get-started">
          <img src={images.bgStarted} alt="" className='bg-black' />

          <div className="get-started-container">
            <div className="started-text">
              <h1>Get Started</h1>

              <div className="started-description">
                Resources to help you learn and build your blockchain efficiently.
              </div>
            </div>

            <div className="started-links">
              <Link to='https://docs.viction.xyz/' target='_blank' className="item-link text-hover-underline-white">
                Technical Docs
              </Link>
              <Link to='https://github.com/BuildOnViction' target='_blank' className="item-link text-hover-underline-white">
                Github
              </Link>
              <Link to='https://hi@viction.xyz/' target='_blank' className="item-link text-hover-underline-white">
                Get Support
              </Link>
            </div>
          </div>

          <img src={images.circleDot4} alt="" className='circle-dot-4' />
        </div>
      </div>
    </div>
  )
}

export default VictionDA