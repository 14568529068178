import 'normalize.css'
import { arrRouter } from 'commons/routers'
import Footer from 'layouts/Footer'
import Header from 'layouts/Header'
import { useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HeaderWallet from 'layouts/HeaderWallet'
import NotFound from 'pages/NotFound'
import { ActionContext } from 'controller/context/ActionProvider'

function App() {
  const { pathname } = useLocation()
  const { state } = useContext(ActionContext)

  const { isHideFt } = state

  const isWalletScreen = pathname === '/wallet'

  const [isLoadingFirst, setIsLoadingFirst] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingFirst(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (!isLoadingFirst) {
      window.scrollTo({
        top: 0,
      })
    }
  }, [pathname])

  // if (isLoadingFirst)
  //   return (
  //     <div className='loading-screen'>
  //       <span className='icon-web_logo icon-logo-loading'></span>
  //     </div>
  //   )

  useEffect(() => {
    if (isWalletScreen) {
      document.body.style.backgroundColor = '#1e1e1e'
    } else {
      document.body.style.backgroundColor = 'unset'
    }
  }, [isWalletScreen])

  return (
    <div className='App'>
      {!isWalletScreen ? <Header /> : <HeaderWallet />}

      <Routes>
        {arrRouter.map((item, i) => {
          return <Route path={item.path} element={item.element} />
        })}
        <Route path='*' element={<NotFound />} />
      </Routes>

      {!isWalletScreen && <Footer />}
    </div>
  )
}

export default App
